import Home from "components/Home";
import {
  LazyBookingDetail,
  LazyCommentPageDetail,
  LazyEarnMoney,
  LazyFranchising,
  LazyInstantQuoteDetail,
  LazyInstantQuoteDetail17Preface,
  LazyInstantQuoteDetailAccordion,
  LazyInstantQuoteDetailAccordionGyeon,
  LazyMaterialsCoating,
  LazyMaterialsPPF,
  LazyNotFound,
  LazyOurIdeal,
  LazyOurTeam,
  LazyPromotionDetail,
  LazyQuizzes,
  LazyResultPage,
  LazySuccess,
  LazyTest,
  LazyWorks
} from "./lazyComponent";
import Article from "components/Works/Article";
import { Tabs_Title_Earnmoney_Success, Tabs_Title_Reservation_Cancel, Tabs_Title_Reservation_Failed, Tabs_Title_Reservation_Invalid, Tabs_Title_SendEmailVeri_Failed, Tabs_Title_SendEmailVeri_Success, Tabs_Title_VeriEmailAddr_Failed, Tabs_Title_VeriEmailAddr_Success } from "./text";

export default [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "about",
    element: <LazyOurTeam />
  },
  {
    path: "commentd",
    element: <LazyCommentPageDetail />
  },
  {
    path: "earnmoney",
    children: [
      {
        path: "",
        element: <LazyEarnMoney />
      },
      {
        path: "success",
        element: <LazyResultPage text="註冊成功, 請查看你的電郵。" title={Tabs_Title_Earnmoney_Success} />
      }
    ]
  },
  {
    path: "gyeon",
    element: <LazyInstantQuoteDetailAccordionGyeon />,
  },
  {
    path: "ideal",
    element: <LazyOurIdeal />
  },
  {
    path: "ppf",
    element: <LazyInstantQuoteDetailAccordion />
  },
  {
    path: "window-film",
    element: <LazyInstantQuoteDetail17Preface />
  },
  {
    path: "product/ppf",
    element: <LazyMaterialsPPF />
  },
  {
    path: "product/coating",
    element: <LazyMaterialsCoating />
  },
  {
    path: "quizzes",
    element: <LazyQuizzes />
  },
  {
    path: "reservation2",
    element: <LazyBookingDetail />
  },
  // new link -- start
  {
    path: "reserveGlossPPF",
    element: <LazyBookingDetail item_type_id={2} />
  },
  {
    path: "reserveMattePPF",
    element: <LazyBookingDetail item_type_id={3} />
  },
  {
    path: "reserveGyeonCoating",
    element: <LazyBookingDetail item_type_id={4} />
  },
  {
    path: "reservePPFCoating",
    element: <LazyBookingDetail item_type_id={5} />
  },
  {
    path: "reserveWindshieldCoating",
    element: <LazyBookingDetail item_type_id={6} />
  },
  {
    path: "reserveSunroofFilm",
    element: <LazyBookingDetail item_type_id={9} />
  },
  {
    path: "reservePartPPF",
    element: <LazyBookingDetail item_type_id={10} />
  },
  {
    path: "reserveAirDefender",
    element: <LazyBookingDetail item_type_id={15} />
  },
  {
    path: "reserveWindowFilm",
    element: <LazyBookingDetail item_type_id={17} />
  },
  // new link -- end
  {
    path: "reservation",
    children: [
      {
        path: "cancel",
        element: <LazyResultPage text=" 預約取消。" type="error" title={Tabs_Title_Reservation_Cancel} />
      },
      {
        path: "failed",
        element: <LazyResultPage text=" 預約失敗。" type="error" title={Tabs_Title_Reservation_Failed} />
      },
      {
        path: "invalid",
        element: <LazyResultPage text=" 無效預約。" type="error" title={Tabs_Title_Reservation_Invalid} />
      },
      {
        path: "success",
        element: <LazySuccess />
      }
    ]
  },
  {
    path: "send_email_verification",
    children: [
      {
        path: "failed",
        element: <LazyResultPage text="發送驗證電郵失敗。" type="error" title={Tabs_Title_SendEmailVeri_Failed} />
      },
      {
        path: "success",
        element: <LazyResultPage text="成功發送驗證電郵。" title={Tabs_Title_SendEmailVeri_Success} />
      }
    ]
  },
  {
    path: "verify_email_address",
    children: [
      {
        path: "failed",
        element: <LazyResultPage text="驗證電郵地址失敗。" type="error" title={Tabs_Title_VeriEmailAddr_Failed} />
      },
      {
        path: "success",
        element: <LazyResultPage text="成功驗證電郵地址。" title={Tabs_Title_VeriEmailAddr_Success} />
      }
    ]
  },
  {
    path: "works",
    children: [
      {
        path: "",
        element: <LazyWorks />
      },
      {
        path: "article/:id",
        element: <Article />
      }
    ]
  },
  {
    path: "*",
    element: <LazyNotFound />
  }
]