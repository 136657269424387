import { manuYearCreator } from "utils/func"
import { Pathname_ContactUs } from "./pathname"
import {
    AboutMaterials,
    AboutUs,
    BaseScratchRemoving,
    Close,
    Coating,
    COATING,
    Coating9H,
    Coating9HPlus,
    CoatingNano,
    CoatingThickness1P2,
    CoatingThicknessP3,
    CoatingThicknessP5,
    CoatingWaterEffectSkin,
    CoatingWaterEffectTop,
    COATING_service,
    Column_Antifouling,
    Column_AntiUV,
    Column_Antiyellowing,
    Column_Coating,
    Column_CoatingGlossEffect,
    Column_CoatingThickness,
    Column_CoatingWaterEffect,
    Column_Dustproof,
    Column_Gloss,
    Column_Gyeon,
    Column_HandFoam,
    Column_InsulationEffect,
    Column_InsulationThickness,
    Column_MService,
    Column_PPFHeadlight,
    Column_PPFThickness,
    Column_Protection,
    Column_ScratchReparing,
    Column_Selfhealing,
    Column_Validity,
    Column_WaterEffect,
    Company,
    Compare_inDetail,
    ContactUs,
    Contain,
    Customer,
    DeepPolish,
    Durability,
    EN,
    ErrorMsg_BrandName,
    ErrorMsg_CarModel,
    ErrorMsg_ManuYear,
    ErrorMsg_u_email,
    ErrorMsg_u_lpn,
    ErrorMsg_u_name,
    ErrorMsg_u_tel,
    GetVCodeBySMS,
    GYEONTitle,
    GYEON_Coating,
    GYEON_D,
    GYEON_PPF,
    HandWrapped,
    Hyphen,
    ID_booking_brandName,
    ID_booking_carModel,
    ID_booking_manuYear,
    ID_booking_u_email,
    ID_booking_u_lpn,
    ID_booking_u_name,
    ID_booking_u_tel,
    ID_CustomerAcc,
    ID_PartnerPersonAcc,
    ID_PartnerPersonPwd,
    ID_VCode,
    Income,
    InputText_BrandName,
    InputText_CarModel,
    InputText_Email,
    InputText_LicensePlateNo,
    InputText_ManuYear,
    InputText_Phone,
    InputText_PhoneOrEmail,
    InputText_PWD,
    InputText_Username,
    InputType_Customer,
    InputType_Input,
    InputType_Login,
    InputType_LoginBTN,
    InputType_Partner,
    InputType_PhoneOrEmail,
    InputType_Reg,
    InputType_VCodeBTN,
    Login,
    LoginPageTitle,
    Membership,
    Mil7P5,
    Mil8P2,
    OurIdea,
    OurTeam,
    OurTeam_AdminTeam,
    OurTeam_CommunityTeam,
    OurTeam_ExecTeam,
    Package_allInOne,
    Package_flash,
    Package_half_sunroof,
    Package_matte,
    Package_mattePlus,
    Package_panoramic,
    Package_plus,
    Package_pure,
    Package_syncro,
    Package_syncroPlus,
    Partner,
    Path_AboutMaterials,
    Path_AboutUs,
    Path_Service,
    Person,
    Phd_booking_lpn,
    PPF,
    PPFThickness,
    PPFTitle,
    PPF_A,
    PPF_A_Short,
    PPF_G,
    PPF_GA,
    PPF_G_Short,
    PPF_Part,
    PPF_T,
    PreventEffectively,
    PriceLowerTo,
    PriceUpTo,
    PricingAndBooking,
    RcmdService_phd,
    RcmdSubService_phd,
    Readmore,
    Service,
    Staff_comm,
    Staff_tech,
    Validity12M,
    Validity18M,
    Validity24M,
    Validity36M,
    Validity48M,
    VCode,
    WorkingTime,
    Year1,
    Year2,
    Year3,
    Mil6P8,
    Column_Coating180,
    Anti,
    Type_Materials_Coating_None,
    Type_Materials_Coating_Items,
    Column_CoatingInfinite,
    All,
    ID_works_all,
    CarWin_Part,
    Column_GYEON_PPF,
    WINDOW_Protective,
    Glass
} from "./text"

const { pathname, search } = window.location
const pathnameWithoutBase = pathname.split("/")
// change it when images are changed
export const isImageChanged = "?v=0.0.8"

// regular expression
export const RegEx_tel = new RegExp(/^[0-9]{8,8}$/)
export const RegEx_email = new RegExp(/[0-9A-Za-z]+@[0-9A-Za-z]+\.[a-z]{2,3}/)
export const RegEx_lpn = new RegExp()

// constant
const IMG_iconStaff1 = `/web/uploads/images/icons/icon_staff_1.svg${isImageChanged}`
const IMG_iconStaff2 = `/web/uploads/images/icons/icon_staff_2.svg${isImageChanged}`
const IMG_iconRate5 = `/web/uploads/images/icons/icon_rate_5.svg${isImageChanged}`
const IMG_iconRate4 = `/web/uploads/images/icons/icon_rate_4.svg${isImageChanged}`
const IMG_iconRate3 = `/web/uploads/images/icons/icon_rate_3.svg${isImageChanged}`
const IMG_iconRate2 = `/web/uploads/images/icons/icon_rate_2.svg${isImageChanged}`
const IMG_iconRate1 = `/web/uploads/images/icons/icon_rate_1.svg${isImageChanged}`
const IMG_iconCrownOn = `/web/uploads/images/icons/icon_crown_on.svg${isImageChanged}`
const IMG_iconCrownOff = `/web/uploads/images/icons/icon_crown_off.svg${isImageChanged}`

// common
export const homepage = "https://" + window.location.host + "/"
export const prod_donain_name = "mrwrapper.com.hk"


export const homepageLogo = "/"
export const homepageLogoSrc = `/web/uploads/images/homepage/header/full_logo.svg${isImageChanged}`
export const homepageLogoWhiteSrc = `/web/uploads/images/homepage/header/full_logo_white.svg${isImageChanged}`
export const homepageMenuIconMobile = `/web/uploads/images/homepage/mobile/menu-icon.svg${isImageChanged}`
export const homepageMenuIconWhiteMobile = `/web/uploads/images/homepage/mobile/menu-icon_white.svg${isImageChanged}`
export const lineYB = `/web/uploads/images/line-y&b.svg${isImageChanged}`
export const lineWB = `/web/uploads/images/line-w&b.svg${isImageChanged}`
export const lineYW = `/web/uploads/images/line-y&w.svg${isImageChanged}`
export const tick = `/web/uploads/images/tick.svg${isImageChanged}`
export const searchIcon = `/web/uploads/images/search.svg${isImageChanged}`
export const blockBY_L = `/web/uploads/images/homepage/productDescription/product-ppf-block.svg${isImageChanged}`
export const blockBY_R = `/web/uploads/images/homepage/productDescription/product-coating-block.svg${isImageChanged}`

// login page
export const waitingTimeForVCode = 60
export const loginPageTitle = LoginPageTitle
export const tabsData = [
    {
        id: 1,
        type: InputType_Customer,
        content: Customer,
        className: "login",
        classNameActive: "login active",
        hidden: true,
        disable: false
    },
    {
        id: 2,
        type: InputType_Partner,
        content: Partner,
        className: "reg",
        classNameActive: "reg active",
        hidden: true,
        disable: false
    }
]
export const loginPageData = [
    {
        id: 1,
        type: InputType_Login,
        className: "login_detail active",
        tabsHidden: true,
        tabsDisable: false,
        tabs: [
            {
                id: 1,
                className: "person",
                classNameActive: "person inactive",
                src: `/web/uploads/images/loginPage/user_active.svg${isImageChanged}`,
                srcInactive: `/web/uploads/images/loginPage/user_inactive.svg${isImageChanged}`,
                content: Person,
                hidden: false,
                disable: false
            },
            {
                id: 2,
                className: "compony",
                classNameActive: "compony inactive",
                src: `/web/uploads/images/loginPage/briefcase_active.svg${isImageChanged}`,
                srcInactive: `/web/uploads/images/loginPage/briefcase_inactive.svg${isImageChanged}`,
                content: Company,
                hidden: false,
                disable: false
            }
        ],
        children: [
            {
                id: 1,
                type: InputType_PhoneOrEmail,
                inputID_Phone: ID_CustomerAcc,
                label_Phone: InputText_Phone,
                label_Email: InputText_Email,
                inputType: "text",
                forgetPwdDisable: true
            },
            {
                id: 2,
                type: InputType_VCodeBTN,
                text: GetVCodeBySMS
            },
            {
                id: 3,
                type: InputType_Input,
                inputID_Input: ID_VCode,
                label_Input: VCode,
                inputType: "text",
                forgetPwdDisable: true
            },
            {
                id: 4,
                type: InputType_LoginBTN,
                text: Login
            }
        ],
        hidden: false,
        disable: false
    },
    {
        id: 2,
        type: InputType_Reg,
        className: "reg_detail active",
        tabsHidden: true,
        tabsDisable: false,
        tabs: [
            {
                id: 1,
                className: "person",
                classNameActive: "person inactive",
                src: `/web/uploads/images/loginPage/user_active.svg${isImageChanged}`,
                srcInactive: `/web/uploads/images/loginPage/user_inactive.svg${isImageChanged}`,
                content: Person,
                hidden: false,
                disable: false
            },
            {
                id: 2,
                className: "compony",
                classNameActive: "compony inactive",
                src: `/web/uploads/images/loginPage/briefcase_active.svg${isImageChanged}`,
                srcInactive: `/web/uploads/images/loginPage/briefcase_inactive.svg${isImageChanged}`,
                content: Company,
                hidden: false,
                disable: false
            }
        ],
        children: [
            {
                id: 1,
                type: InputType_Input,
                inputID_Input: ID_PartnerPersonAcc,
                // label_Input: InputText_PhoneOrEmail,
                label_Input: InputText_Email,
                inputType: "text",
                forgetPwdDisable: true
            },
            {
                id: 2,
                type: InputType_Input,
                inputID_Input: ID_PartnerPersonPwd,
                label_Input: InputText_PWD,
                inputType: "password",
                forgetPwdDisable: true
            },
            {
                id: 3,
                type: InputType_LoginBTN,
                text: Login
            }
        ],
        hidden: false,
        disable: false
    }
]

// Header component
export const headerLogoutSrc = `/web/uploads/images/homepage/logout.svg${isImageChanged}`
export const headerLogoutSrcForMobile = `/web/uploads/images/homepage/mobile/logout.svg${isImageChanged}`
export const headerItemData = [
    {
        id: 1,
        path: Path_AboutUs,
        to: `${pathnameWithoutBase[1]}${search}`,
        text: AboutUs,
        hasArrow: true,
        subpageComponentName: "AboutUs",
        hideAfterLogin: false,
        disable: false
    },
    {
        id: 2,
        path: Path_Service,
        to: `${pathnameWithoutBase[1]}${search}`,
        text: Service,
        hasArrow: true,
        subpageComponentName: "Service",
        hideAfterLogin: false,
        disable: false
    },
    {
        id: 3,
        path: Path_AboutMaterials,
        to: `${pathnameWithoutBase[1]}${search}`,
        text: AboutMaterials,
        hasArrow: true,
        subpageComponentName: "AboutMaterials",
        hideAfterLogin: false,
        disable: false
    },
    {
        id: 4,
        path: null,
        to: `${pathnameWithoutBase[1]}${search}`,
        text: Membership,
        hasArrow: false,
        subpageComponentName: null,
        hideAfterLogin: false,
        disable: true
    },
    {
        id: 5,
        path: null,
        to: "earnmoney",
        text: Income,
        hasArrow: false,
        subpageComponentName: null,
        hideAfterLogin: true,
        disable: false
    },
    // {
    //     id: 6,
    //     path: null,
    //     to: "",
    //     text: EN,
    //     hasArrow: false,
    //     subpageComponentName: null,
    //     hideAfterLogin: false,
    //     disable: false
    // }
    {
        id: 7,
        path: null,
        to: Pathname_ContactUs,
        text: ContactUs,
        hasArrow: false,
        subpageComponentName: null,
        hideAfterLogin: false,
        disable: false
    }

]
export const headerAboutusSrc = `/web/uploads/images/homepage/header/aboutus.svg${isImageChanged}`
export const headerAboutus = [
    {
        id: 1,
        to: "/about",
        text: OurTeam,
        disable: false
    },
    {
        id: 2,
        to: "/ideal",
        text: OurIdea,
        disable: false
    }
]
export const headerServiceSrc = `/web/uploads/images/homepage/header/service.svg${isImageChanged}`
export const headerService = [
    {
        id: 1,
        titleSrc: `/web/uploads/images/homepage/header/service_ppf.svg${isImageChanged}`,
        titleText: PPFTitle,
        titleBtnText: Readmore,
        titleBtnTo: "ppf",
        disable: false,
        children: [
            {
                id: 1,
                href: "/reservation2?item_type=2",
                text: PPF_G,
                disable: false
            },
            {
                id: 2,
                href: "/reservation2?item_type=3",
                text: PPF_A,
                disable: false
            },
            {
                id: 3,
                href: "/reservation2?item_type=10",
                text: PPF_Part,
                disable: false
            }

        ]
    },
    {
        id: 2,
        titleSrc: `/web/uploads/images/homepage/header/service_gyeon.svg${isImageChanged}`,
        titleText: GYEONTitle,
        titleBtnText: Readmore,
        titleBtnTo: "gyeon",
        disable: false,
        children: [
            {
                id: 1,
                href: "/reservation2?item_type=4",
                text: GYEON_Coating,
                disable: false
            },
            {
                id: 2,
                href: "/reservation2?item_type=6",
                text: GYEON_D,
                disable: false
            },
            {
                id: 3,
                href: "/reservation2?item_type=5",
                text: GYEON_PPF,
                disable: false
            }
        ]
    },
    {
        id: 3,
        titleSrc: `/web/uploads/images/homepage/header/menu_icon_glass.svg${isImageChanged}`,
        // titleText: Anti,
        titleText: Glass,
        titleBtnText: "",
        titleBtnTo: "",
        disable: false,
        children: [
            // {
            //     id: 1,
            //     href: "/reservation2?item_type=15",
            //     text: COATING_service,
            //     disable: false
            // },
            {
                id: 4,
                href: "/reservation2?item_type=9",
                text: PPF_T,
                disable: false
            }
            // ,
            // {
            //     id: 2,
            //     href: "/reservation2?item_type=17",
            //     text: WINDOW_Protective,
            //     disable: false
            // }
        ]
    }
]
export const headerAboutMaterialsSrc = `/web/uploads/images/homepage/header/aboutmaterials.svg${isImageChanged}`
export const headerAboutMaterials = [
    {
        id: 1,
        to: "/product/ppf",
        text: PPF,
        disable: false
    },
    {
        id: 2,
        to: "/product/coating",
        text: COATING,
        disable: false
    }
]
// header mobile 
export const mobileHeaderMenuLogoSrc = `/web/uploads/images/homepage/header/mobile/logo-white.svg${isImageChanged}`
export const mobileHeaderMenuData = [
    {
        id: 1,
        text: AboutUs,
        to: null,
        hasArrow: true,
        disable: false,
        hideAfterLogin: false,
        subItems: [
            {
                id: 1,
                text: OurIdea,
                to: "/ideal",
                disable: false
            },
            {
                id: 2,
                text: OurTeam,
                to: "/about",
                disable: false
            },
        ]
    },
    {
        id: 2,
        text: Service,
        to: null,
        hasArrow: true,
        disable: false,
        hideAfterLogin: false,
        subItems: [
            {
                id: 1,
                iconSrc: `/web/uploads/images/mobile/menu/service_ppf.svg${isImageChanged}`, // server
                text: PPFTitle,
                titlebarBtnText: Readmore,
                to: "/ppf",
                subItems: [
                    {
                        id: 1,
                        text: PPF_G,
                        to: "/reservation2?item_type=2"
                    },
                    {
                        id: 2,
                        text: PPF_A,
                        to: "/reservation2?item_type=3"
                    },
                    {
                        id: 3,
                        text: PPF_Part,
                        to: "/reservation2?item_type=10"
                    }
                ]
            },
            {
                id: 2,
                iconSrc: `/web/uploads/images/mobile/menu/service_gyeon.svg${isImageChanged}`, // server
                text: GYEONTitle,
                titlebarBtnText: Readmore,
                to: "/gyeon",
                subItems: [
                    {
                        id: 1,
                        text: GYEON_Coating,
                        to: "/reservation2?item_type=4"
                    },
                    {
                        id: 2,
                        text: GYEON_D,
                        to: "/reservation2?item_type=6"
                    }
                    ,
                    {
                        id: 3,
                        text: GYEON_PPF,
                        to: "/reservation2?item_type=5"
                    }
                ]
            },
            {
                id: 3,
                iconSrc: `/web/uploads/images/mobile/menu/menu_icon_glass.svg${isImageChanged}`, // server
                // text: Anti,
                text: Glass,
                titlebarBtnText: '',
                to: "",
                subItems: [
                    // {
                    //     id: 1,
                    //     text: COATING_service,
                    //     to: "/reservation2?item_type=15"
                    // },
                    {
                        id: 4,
                        text: PPF_T,
                        to: "/reservation2?item_type=9"
                    }
                    // ,
                    // {
                    //     id: 2,
                    //     text: WINDOW_Protective,
                    //     to: "/reservation2?item_type=17"
                    // }
                ]
            }
        ]
    },
    {
        id: 3,
        text: AboutMaterials,
        to: null,
        hasArrow: true,
        disable: false,
        hideAfterLogin: false,
        subItems: [
            {
                id: 1,
                text: PPF,
                to: "/product/ppf",
                disable: false
            },
            {
                id: 2,
                text: COATING,
                to: "/product/coating",
                disable: false
            }
        ]
    },
    {
        id: 4,
        text: Membership,
        to: null,
        hasArrow: false,
        disable: true,
        hideAfterLogin: false,
        subItems: null
    },
    {
        id: 5,
        text: Income,
        to: "/earnmoney",
        hasArrow: false,
        disable: false,
        hideAfterLogin: true,
        subItems: null
    },
    {
        id: 6,
        text: ContactUs,
        to: Pathname_ContactUs,
        hasArrow: false,
        disable: false,
        hideAfterLogin: false,
        subItems: null
    }
]

// banner component
export const bannerSrc = `/web/uploads/images/homepage/banner/bg_m.jpg${isImageChanged}`
export const bannerBG = `/web/uploads/images/homepage/banner/home_banner_desktop.svg${isImageChanged}`  // 111111111111111111111111111111111111111111111
export const bannerScrollArrow = `/web/uploads/images/homepage/banner/scroll-arrow.svg${isImageChanged}`
// banner mobile
export const bannerBGForMobile = `/web/uploads/images/homepage/banner/mobile/banner-foreground.svg${isImageChanged}` //11111111111111111111111111111111111111111
export const bannerSrcForMobile = `/web/uploads/images/homepage/banner/mobile/bg_mobile.jpg${isImageChanged}`

//InstantQuote component
export const instantQuoteBG = `/web/uploads/images/homepage/instantQuote/instant-quote-bg.svg${isImageChanged}`
export const bookingCardGroupData = [
    {
        id: 1,
        src: `/web/uploads/images/banner/home_box-01.png${isImageChanged}`,
        to: "/ppf"
    },
    {
        id: 2,
        src: `/web/uploads/images/banner/home_box-02.png${isImageChanged}`,
        to: "/gyeon"
    },
    {
        id: 3,
        src: `/web/uploads/images/banner/home_box-03.png${isImageChanged}`,
        to: ""
    },
]
export const instantquoteItemData = [
    {
        id: 1,
        src: `/web/uploads/images/instantquote_item/ppf-g-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/ppf-g-b&y.svg${isImageChanged}`,
        text: PPF_G_Short,
        to: "/reservation2?item_type=2"
    },
    {
        id: 2,
        src: `/web/uploads/images/instantquote_item/ppf-at-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/ppf-at-b&y.svg${isImageChanged}`,
        text: PPF_A_Short,
        to: "/reservation2?item_type=3"
    },
    {
        id: 3,
        src: `/web/uploads/images/instantquote_item/carFilm-part-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/carFilm-part-b&y.svg${isImageChanged}`,
        text: PPF_Part,
        to: "/reservation2?item_type=10"
    },
    {
        id: 4,
        src: `/web/uploads/images/instantquote_item/carFilm-whole-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/carFilm-whole-b&y.svg${isImageChanged}`,
        text: PPF_T,
        to: "/reservation2?item_type=9"
    },
    {
        id: 6,
        src: `/web/uploads/images/instantquote_item/gyeonQ2-whole-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/gyeonQ2-whole-b&y.svg${isImageChanged}`,
        text: GYEON_Coating,
        to: "/reservation2?item_type=4"
    },
    {
        id: 7,
        src: `/web/uploads/images/instantquote_item/gyeonQ2-window-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/gyeonQ2-window-b&y.svg${isImageChanged}`,
        text: GYEON_D,
        to: "/reservation2?item_type=6"
    },
    {
        id: 5,
        src: `/web/uploads/images/instantquote_item/gyeonQ2-ppf-w&b.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/gyeonQ2-ppf-b&y.svg${isImageChanged}`,
        text: GYEON_PPF,
        to: "/reservation2?item_type=5"
    },
    {
        id: 8,
        src: `/web/uploads/images/instantquote_item/clean_icon-02.svg${isImageChanged}`,
        hoverSrc: `/web/uploads/images/instantquote_item/clean_icon-03.svg${isImageChanged}`,
        text: COATING_service,
        to: "/reservation2?item_type=15"
    }
]
export const instantquoteReadmoreVideoData = [
    {
        id: 1,
        videoURL: "/web/video/banner.mp4",
        videoURL_mobile: "/web/video/banner.mp4",
        srcSwitch: false,
        srcURL: `/web/uploads/images/instantquoteReadmore/gyeon_wallpaper_video.jpg${isImageChanged}`,
        srcURL_mobile: `/web/uploads/images/instantquoteReadmore/mobile/gyeon_wallpaper_video_mobile.jpg${isImageChanged}`,
        titlebar_title: "質量．理念．無可比擬",
        content: "優質的材料 加上 施工團隊的堅持，令我們把服務做到一絲不苟；透過了解 <span class='noWrap'>Mr. Wrapper</span> 的品牌故事和理念，你會認同我們是你做過最好的選擇 !",
        footerBtn: [
            {
                id: 1,
                text: "更多施工作品",
                to: "",
                // href: "https://www.instagram.com/mrwrapper.hk/",
                href: "/works",
                disable: false,
                hide: false
            },
            {
                id: 2,
                text: "關於物料",
                to: "/ppf",
                href: "",
                disable: false,
                hide: false
            }
        ]
    }
]
export const instantquoteReadmoreData = [
    {
        id: 1,
        title: PPF_G_Short,
        content: "<span class='noWrap'>Mr. Wrapper</span> 在保護膜服務上採用厚度高達 8.2mi 的超強韌性 TPU 物料，除了有效抵抗酸雨、沙石劃傷以外，更具備自我修復功能！",
        btnTo: "/reservation2?item_type=2",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-gloss.jpg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-gloss_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceLowerTo,
                content: "<span class='littleIconText'>$17,600</span>"
            },
            {
                id: 2,
                title: PPFThickness,
                content: "<span class='littleIconText'>7.5mil</span> 起"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>5天</span>"
            }
        ]
    },
    {
        id: 2,
        title: PPF_A_Short,
        content: "<span class='noWrap'>Mr. Wrapper</span> 在啞色保護膜服務上採用厚度高達 7.5mil 的超強韌性 TPU 物料！ 選用啞面物料的話，車身的反光度會略為減少，讓您的愛車增添一份低調及優雅。",
        btnTo: "/reservation2?item_type=3",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-matte.jpg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-matte_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceLowerTo,
                content: "<span class='littleIconText'>$19,100</span>"
            },
            {
                id: 2,
                title: PPFThickness,
                content: "<span class='littleIconText'>7.5mil</span> 起"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>5天</span>"
            }
        ]
    },
    {
        id: 3,
        title: PPF_T,
        content: "有效阻隔 95%紅外線 及99.9%紫外光，減低車廂內溫度。更具有防爆保護功能，提供全天候的可靠防護。",
        btnTo: "/reservation2?item_type=9",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-sunroof.jpeg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-sunroof_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceLowerTo,
                content: "<span class='littleIconText'>$2,100</span>"
            },
            {
                id: 2,
                title: PreventEffectively,
                content: "<span class='littleIconText'>99%UV</span>"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>1天</span>"
            }
        ]
    },
    {
        id: 4,
        title: PPF_Part,
        content: "如果您的愛車因意外或事故而需要為某部份重新貼膜，<span class='noWrap'>Mr. Wrapper</span>的局部貼膜可以按位置局部貼膜，報價透明劃一收費，能讓您的愛車重獲保護。",
        btnTo: "/reservation2?item_type=10",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-part.jpg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-part_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceUpTo,
                content: "<span class='littleIconText'>貼膜面積</span>"
            },
            {
                id: 2,
                title: PPFThickness,
                content: "<span class='littleIconText'>7.5mil</span> 起"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>2天</span>"
            }
        ]
    }
]
export const instantquoteReadmoreGyeonVideoData = [
    {
        id: 1,
        videoURL: "/web/video/貼膜份子G-Class G63 AMG(long Ver)V2 no logo.mp4",
        videoURL_mobile: "/web/video/貼膜份子G-Class G63 AMG(long Ver)V2 no logo.mp4",
        srcSwitch: false,
        srcURL: `/web/uploads/images/instantquoteReadmore/gyeon_wallpaper_video.jpg${isImageChanged}`,
        srcURL_mobile: `/web/uploads/images/instantquoteReadmore/mobile/gyeon_wallpaper_video_mobile.jpg${isImageChanged}`,
        titlebar_title: "質量．來至官方認證",
        content: "我們是 GYEON 官方認可的香港施工店 ；由通過認證的專業技師分析車身狀態後，透過GYEON鍍膜技術為你進行車漆修復及鍍膜施工，確保交貨的的質素具細緻，而且高水準",
        footerBtn: [
            {
                id: 1,
                text: "更多施工作品",
                to: "",
                // href: "https://www.instagram.com/mrwrapper.hk/",
                href: "/works",
                disable: false,
                hide: false
            },
            {
                id: 2,
                text: "關於物料",
                to: "/product/coating",
                href: "",
                disable: false,
                hide: false
            }
        ]
    }
]
export const instantquoteReadmoreGyeonData = [
    {
        id: 1,
        title: GYEON_Coating,
        content: "GYEON Q² 專利配方結合了兩個形成優質塗層中最重要的特質，在光澤和耐用方面作出完美平衡 ，提供於不同環境因素下耐用和亮度的抗化學藥劑性，亦能做出玻璃般的光澤。",
        btnTo: "/reservation2?item_type=4",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-coating.jpg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-coating_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceLowerTo,
                content: "<span class='littleIconText'>$3,520</span>"
            },
            {
                id: 2,
                title: Durability,
                content: "長達 <span class='littleIconText'>3年</span>"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>3天</span>"
            }
        ]
    },
    {
        id: 2,
        title: GYEON_D,
        content: "<span class='noWrap'>Mr. Wrapper</span> 會先為車窗去除油膜和水漬，再加上 GYEON 的鍍膜劑能透過堆疊效果達到三層式防護，為擋風玻璃提供最強的跣水及防污性能！",
        btnTo: "/reservation2?item_type=6",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-glass.jpeg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-glass_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceLowerTo,
                content: "<span class='littleIconText'>$1,430</span>"
            },
            {
                id: 2,
                title: Durability,
                content: "長達 <span class='littleIconText'>1年</span>"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>1天</span>"
            }
        ]
    },
    {
        id: 3,
        title: GYEON_PPF,
        content: "使用PPF專⽤鍍膜後，保護層整體厚度⾼達8.8mil，在三層涂層的保護下除了讓您的愛⾞有更強保護外，表⾯的鍍膜能讓⾞⾝清潔和抗污能⼒⼤幅提升，使您更輕鬆保養愛⾞。",
        btnTo: "/reservation2?item_type=5",
        btnText: PricingAndBooking,
        src: `/web/uploads/images/booking/detail-ppfcoating.jpeg${isImageChanged}`,
        srcForMobile: `/web/uploads/images/instantquoteReadmore/mobile/detail-ppfcoating_mobile.png${isImageChanged}`,
        icons: [
            {
                id: 1,
                title: PriceLowerTo,
                content: "<span class='littleIconText'>$4,950</span>"
            },
            {
                id: 2,
                title: Durability,
                content: "長達 <span class='littleIconText'>3年</span>"
            },
            {
                id: 3,
                title: WorkingTime,
                content: "約 <span class='littleIconText'>3天</span>"
            }
        ]
    }
]

export const instantquoteDetail17Preface = [{
    id: 1,
    videoURL: "/web/video/貼膜份子G-Class G63 AMG(long Ver)V2 no logo.mp4",
    videoURL_mobile: "/web/video/貼膜份子G-Class G63 AMG(long Ver)V2 no logo.mp4",
    srcSwitch: true,
    srcURL: `/web/uploads/images/instantquotepreface417/Rectangle.png${isImageChanged}`,
    srcURL_mobile: `/web/uploads/images/instantquotepreface417/mobile/Group12.png${isImageChanged}`,
    titlebar_title: "車窗隔熱膜",
    content: "主要功能是減緩車內熱量提升，同時亦可抵擋UV紫外線和紅外線對人體及車內物品的傷害。太陽光主要分為三大類，紫外線、紅外線和可見光，三者都會釋放熱能令車廂變熱。而車窗隔熱膜採用多層納米陶瓷技術，維持高清能見度，吸收或反射熱能，減緩車廂溫度上升的速度，從而達致隔熱效果，降低汽車電/油耗，節能環保。",
    footerBtn: [
        {
            id: 1,
            text: "關於物料",
            to: "",
            href: "/works",
            disable: false,
            hide: false,
            moveDown: true
        }
    ]
}]

export const franchising17Preface = [
    {
        id: 3,
        bgImage: ``,
        bgColor: "",
        isTag: false,
        tag: "配套",
        tagColor: "#ffffff",
        title: "",
        slogan: "任何地方！",
        sloganColor: "#ffffff",
        lineSrc: lineYB,
        subtitle1: "更多弱勢社群就業",
        subtitle1Color: "#ffcc01",
        subtitle2: "",
        subtitle2Color: "",
        description: `Mr. Wrapper 的總店位置於九⿓灣，我們希望透過特許經營計劃，在任何地⽅都能夠看到Mr. Wrapper的特許經營店，透過向每⼀位⾞主提供汽⾞護理的會籍計劃，為更多弱勢社群提供就業服務 ！`,
        descriptionColor: "#ffffff",
        isAlignToLeft: false,
        descBoxHeight: "100%",
        markLayer: true,
        markColor: "14,99,170",
        markOpacity: 1,
        bgText: "",
        cover: ``,
        coverBgColor: "",
        swiper: false,
        swiperEffect: "",
        swiperSpeed: 0,
        swiperPosition: "",
        swiperImages: [],
        swiperBgEffect: "fade",
        swiperBgSpeed: 5000,
        swiperBgImages: [
            {
                id: 1,
                src: `/web/uploads/images/instantquotepreface417/unnamed.jpg${isImageChanged}`
            },
            {
                id: 2,
                src: `/web/uploads/images/instantquotepreface417/unnamed2.jpg${isImageChanged}`
            }
        ]
    }
]

export const franchising17PrefaceIconCard =
    // [
    //     {
    //         id: 1,
    //         icon: `/web/uploads/images/instantquotepreface417/icons/window-ppf-feature-10.svg${isImageChanged}`,
    //         desc: "抵擋有害UV紫外線不傷及皮膚",
    //     },
    //     {
    //         id: 2,
    //         icon: `/web/uploads/images/instantquotepreface417/icons/window-ppf-feature-11.svg${isImageChanged}`,
    //         desc: "阻擋陽光直射，預防皮革及內飾老化龜裂",
    //     },
    //     {
    //         id: 3,
    //         icon: `/web/uploads/images/instantquotepreface417/icons/window-ppf-feature-12.svg${isImageChanged}`,
    //         desc: "防止眩光照射影響駕駛安全",
    //     },
    //     {
    //         id: 4,
    //         icon: `/web/uploads/images/instantquotepreface417/icons/window-ppf-feature-13.svg${isImageChanged}`,
    //         desc: "有效降低車箱溫度，令冷氣發揮作用",
    //     },
    //     {
    //         id: 5,
    //         icon: `/web/uploads/images/instantquotepreface417/icons/window-ppf-feature-16.svg${isImageChanged}`,
    //         desc: "有效反射及阻隔部分熱能進入車廂，讓車內有效降溫，降低汽車電/油耗",
    //     },
    //     {
    //         id: 6,
    //         icon: `/web/uploads/images/instantquotepreface417/icons/window-ppf-feature-17.svg${isImageChanged}`,
    //         desc: "防爆功能的汽車隔熱紙，可以降低因意外破裂的玻璃飛散對人體的傷害程度",
    //     }
    // ]

    [
        {
            id: 1,
            title: "阻隔熱源",
            titleImg: `/web/uploads/images/instantquotepreface417/icons/l1-min.jpg${isImageChanged}`,
            subTitle: "",
            content: "有效隔絕太陽熱能，降低車內溫度，隔熱效果持久穩定",
            contentImg: "",
        },
        {
            id: 2,
            title: "隔離眩光",
            titleImg: `/web/uploads/images/instantquotepreface417/icons/r1-min.jpg${isImageChanged}`,
            subTitle: "",
            content: "有效阻隔眩光，避免駕駛者眼睛不適，影響駕駛安全",
            contentImg: "",
        },
        {
            id: 3,
            title: "阻隔紫外線",
            titleImg: `/web/uploads/images/instantquotepreface417/icons/l2-min.jpg${isImageChanged}`,
            subTitle: "",
            content: "抵擋有害UV紫外線不傷及皮膚，有效防止皮膚曬傷",
            contentImg: "",
        },
        {
            id: 4,
            title: "保護內飾",
            titleImg: `/web/uploads/images/instantquotepreface417/icons/r2-min.jpg${isImageChanged}`,
            subTitle: "",
            content: "阻擋陽光直射，預防皮革及內飾老化龜裂受損",
            contentImg: "",
        },
        {
            id: 5,
            title: "節能環保",
            titleImg: `/web/uploads/images/instantquotepreface417/icons/l3-min.jpg${isImageChanged}`,
            subTitle: "",
            content: "有效反射及阻隔部分熱能進入車廂，讓車內有效降溫，降低汽車電/油耗",
            contentImg: "",
        },
        {
            id: 6,
            title: "安全防爆",
            titleImg: `/web/uploads/images/instantquotepreface417/icons/r3-min.jpg${isImageChanged}`,
            subTitle: "",
            content: "防爆功能的汽車隔熱紙，可以降低因意外破裂的玻璃飛散對人體的傷害程度",
            contentImg: "",
        }
    ]

export const stepsData = [
    {
        label: '清潔玻璃',
        subLabel: "深層清潔玻璃及車窗邊框",
        swiperArr: [
            {
                id: 1,
                src: '/web/uploads/images/instantquotepreface417/steps/step11.jpg'
            },
            {
                id: 2,
                src: '/web/uploads/images/instantquotepreface417/steps/step12.jpg'
            },
            {
                id: 3,
                src: '/web/uploads/images/instantquotepreface417/steps/step13.jpg'
            },
            {
                id: 4,
                src: '/web/uploads/images/instantquotepreface417/steps/step14.jpg'
            }
        ]
    },
    {
        label: '手工裁膜',
        subLabel: "全人手沿車窗邊線裁膜",
        swiperArr: [
            {
                id: 1,
                src: '/web/uploads/images/instantquotepreface417/steps/step21.jpg'
            },
            {
                id: 2,
                src: '/web/uploads/images/instantquotepreface417/steps/step22.jpg'
            }
        ]
    },
    {
        label: '熱能烤膜',
        subLabel: "使用熱風槍定出符合玻璃弧度形狀",
        swiperArr: [
            {
                id: 1,
                src: '/web/uploads/images/instantquotepreface417/steps/step31.jpg'
            },
            {
                id: 2,
                src: '/web/uploads/images/instantquotepreface417/steps/step32.jpg'
            }
        ]
    },
    {
        label: '貼膜施工',
        subLabel: "噴上安裝液，將隔熱膜貼到車窗上",
        swiperArr: [
            {
                id: 1,
                src: '/web/uploads/images/instantquotepreface417/steps/step41.jpg'
            },
            {
                id: 2,
                src: '/web/uploads/images/instantquotepreface417/steps/step42.jpg'
            },
            {
                id: 3,
                src: '/web/uploads/images/instantquotepreface417/steps/step43.jpg'
            },
            {
                id: 4,
                src: '/web/uploads/images/instantquotepreface417/steps/step44.jpg'
            }
        ]
    },
    {
        label: '品質檢查',
        subLabel: "進行嚴格檢查，確保品質良好交車",
        swiperArr: []
    }
];

// ProductDescription component
export const productDescriptionBG = `/web/uploads/images/homepage/productDescription/product-description-bg.png${isImageChanged}`
export const productDescriptionPPF = `/web/uploads/images/homepage/productDescription/product-ppf.png${isImageChanged}`
export const productDescriptionCoating = `/web/uploads/images/homepage/productDescription/product-coating.png${isImageChanged}`
export const productDescriptionPPFBlock = `/web/uploads/images/homepage/productDescription/product-ppf-block.svg${isImageChanged}`
export const productDescriptionCoatingBlock = `/web/uploads/images/homepage/productDescription/product-coating-block.svg${isImageChanged}`
export const productDescriptionData = [
    {
        id: 1,
        title: PPFTitle,
        description: "PPF是眾多物料中最具保護性的選擇，可有效防止碎石撞擊、刮花、掉漆和凹痕，保護車漆及減輕小型事故的損壞。",
        btnDisable: false,
        to: "/ppf",
        items: [
            {
                id: 1, content: "強化保護原廠⾞漆"
            },
            {
                id: 2, content: "⾼度耐⿈性能"
            },
            {
                id: 3, content: "⾼達100 次的⾃我修復能⼒"
            },
            {
                id: 5, content: "⾼耐腐蝕性能"
            },
            {
                id: 6, content: "撕膜不留痕跡"
            },
        ]
    },
    {
        id: 2,
        title: GYEON_Coating,
        description: "我們為 GYEON 官方認可香港地區施工店，獲授權使用 GYEON 認證級別的鍍膜產品。想讓您的愛車光潔如新，鍍膜會是您的最好選擇，鍍膜後的車漆色澤更好，易於清潔，觸摸上來更光滑，整個服務更可為您修正車漆、重新拋光及去除花痕。",
        btnDisable: false,
        to: "/gyeon",
        items: [
            {
                id: 1, content: "施工使用劑量: 30ml/車"
            },
            {
                id: 2, content: "表面硬度提升: 9H"
            },
            {
                id: 3, content: "水珠接觸角度: >110"
            },
            {
                id: 4, content: "抗化學藥劑性: PH2-11"
            },
            {
                id: 5, content: "塗層厚度提升: 0.2-0.5qm"
            },
            {
                id: 6, content: "持久保護能力: 18個月/3萬公里"
            },
        ]
    },

]

// Comment component
export const homepageCommentPage = `/web/uploads/images/homepage/commentPage/comment-page-bg.png${isImageChanged}`
export const homepageCommentPageMobile = `/web/uploads/images/homepage/mobile/comment-page-bg.png${isImageChanged}`
export const commentBoxStar = `/web/uploads/images/homepage/commentPage/rating-star.svg${isImageChanged}`
export const commentBoxStarInactive = `/web/uploads/images/homepage/commentPage/rating-star-inactive.svg${isImageChanged}`
export const commentBoxIG = `/web/uploads/images/homepage/ig-user-comment.svg${isImageChanged}`
export const commentPage = [
    {
        "id": 1,
        "username": "Rio梁冠聰",
        "title": "香港足球運動員",
        "src": `/web/uploads/images/comment/梁冠聰.jpeg${isImageChanged}`,
        "content": "年輕時一次很差的鍍膜經驗，花了不少金錢卻毫無後果，讓我把鍍膜這兩個字拒之門外。數年前，再次給鍍膜一個機會可惜效果同樣欠佳。直至這年遇上<span class='noWrap'>Mr Wrapper</span>，膽粗粗再試試擋風玻璃鍍膜，除了即時效果非常顯著，效果不會在短期內打回原形，讓我對鍍膜重拾信心。感謝<span class='noWrap'>Mr Wrapper</span>把我的「寶貝」用心打理，以往很害怕滂沱大雨下揸車，但有了玻璃鍍膜後，反而愛上那些一滴滴的玻璃珠和靜靜地看着他們順滑地溜走。",
        "star": 5,
        "position": "top",
        "ig": "chung_05"
    },
    {
        "id": 2,
        "username": "Sam Man文卓森",
        "title": "旁白君",
        "src": `/web/uploads/images/comment/SamMan.jpeg${isImageChanged}`,
        "content": "揉合專業同人情味嘅服務、對㗎車就好似對朋友咁好！絕對係愛車之人嘅扮靚好去處！而家㗎車去到邊都有人讚！",
        "star": 5,
        "position": null,
        "ig": "samm0123"
    },
    {
        "id": 3,
        "username": "麻利",
        "title": "著名作家",
        "src": `/web/uploads/images/comment/麻利.jpeg${isImageChanged}`,
        "content": "多謝佢哋細心嘅安排，由揀選顏色過程開始，俾咗好多嘅資料同埋預算嘅狀況我去考慮，成個過程令人好安心。貼膜嘅時候亦都有細心跟進每個狀況，當然出到嚟效果非常之好，成架車靚仔晒，從來冇諗過我會咁錫一架二手車，因為<span class='noWrap'>Mr. Wrapper</span>俾咗個新生命佢，而家每次揸出街都覺得好精神！",
        "star": 5,
        "position": null,
        "ig": "malisheep"
    },
    {
        "id": 4,
        "username": "強尼",
        "title": "ViuTV一哥",
        "src": `/web/uploads/images/comment/強尼.jpg${isImageChanged}`,
        "content": "第一次幫架車做鍍膜，效果真係唔錯，<span class='noWrap'>Mr Wrapper</span>嘅環境整潔，令我對佢哋嘅成品有多一份信心。效率高服務好，我一定會再去搵佢哋幫我架車扮靚！",
        "star": 5,
        "position": null,
        "ig": "ilovemyoq"
    },
    {
        "id": 5,
        "username": "袁富華 Ben Gor",
        "title": "資深演員",
        "src": `/web/uploads/images/comment/袁富華.jpg${isImageChanged}`,
        "content": "我好愛錫架E92，但落雨天要打理白色車，對我嚟講真係好吃力，多謝推介我去<span class='noWrap'>Mr Wrapper</span>，試咗佢哋嘅車身護理服務，除咗真心覺得係好OK之外，更正嘅係佢哋嘅社企計劃。我見到佢哋經過專業培訓嘅練習生，三兩下手勢就將我架車清潔到乾乾淨淨兼做埋鍍膜！呢幾日落雨出完車都好易清理，thx!",
        "star": 5,
        "position": null,
        "ig": "benbenyuen"
    }
];
// CommentPageDetail component
export const commentPageDetailBG = `/web/uploads/images/commentPageDetail/commentPageDetailBG.svg${isImageChanged}` //111111111111111111111111111111111
export const commentPageDetailBGForMobile = `/web/uploads/images/commentPageDetail/mobile/commentPageDetailBG.png${isImageChanged}`
export const commentPageDetailTriangleY = `/web/uploads/images/commentPageDetail/dialog-triangle-yellow.svg${isImageChanged}`
export const commentPageDetailTriangleW = `/web/uploads/images/commentPageDetail/dialog-triangle-white.svg${isImageChanged}`
export const commentPageDetailUpperQutoes = `/web/uploads/images/commentPageDetail/quotes-upper.svg${isImageChanged}`
export const commentPageDetailLowerQutoes = `/web/uploads/images/commentPageDetail/quotes-lower.svg${isImageChanged}`

// NGO component 
export const ngoPlanBG = `/web/uploads/images/homepage/NgoPlan/ngo-bg.svg${isImageChanged}`
export const ngoPlanPic = `/web/uploads/images/homepage/NgoPlan/ngo-lowerPart-pic.png${isImageChanged}`
export const ngoPlanPic2 = `/web/uploads/images/homepage/NgoPlan/ngo-lowerPart-pic2.png${isImageChanged}`
export const ngoPlanData = [
    {
        id: 1,
        src: `/web/uploads/images/ngo/ngo.svg${isImageChanged}`,
        text: "<span class='noWrap'>Mr. Wrapper</span> 是一家提供社會服務計劃的汽車護理品牌 -- 亦是劉卓然慈善基金旗下的慈善計劃，我們的NGO服務計劃是針對為不同類別的社福機構合作。向特殊人士或弱勢社群提供工作培訓及機會，我們歡迎任何機構的合作；社企的目的是可持續發展並提供更多的社區援助，每位使用我們服務的顧客也能為弱勢社群提供就業機會，實行授之予魚不如授之予漁，讓他們重投社會。"
    },
    {
        id: 2,
        src: `/web/uploads/images/ngo/hkdse.svg${isImageChanged}`,
        text: "香港唐氏綜合症協會於一九八七年十月正式註冊為非牟利慈善團體，致力為唐氏綜合症人士、殘疾人士、其他智障人士及其家人提供綜合家庭支援及職業復康服務，使服務使用者在個人成長及生活各方面，得以全面發展；以及滿足他們在就業及職業培訓方面的需要。近年，我們致力拓展多項社會企業，希望增加殘疾人士職業訓練和就業機會，推動「社會共融」的概念。"
    }

]

// OurTeamAndBooking
export const teamAndBookingLowPartBG = `/web/uploads/images/homepage/teamAndBooking/team-and-booking-booking-bg.svg${isImageChanged}`
export const teamAndBookingBG = `/web/uploads/images/homepage/teamAndBooking/team-and-booking-banner.jpg${isImageChanged}`
export const teamAndBookingBG2 = `/web/uploads/images/homepage/teamAndBooking/booking-bg.svg${isImageChanged}`
export const teamAndBookingPPF = `/web/uploads/images/homepage/teamAndBooking/booking-ppf.png${isImageChanged}`
export const teamAndBookingData = []

// Booking component
export const bookingDonateImg = `/web/uploads/images/booking/donation.jpeg${isImageChanged}`
export const bookingSuccessImg = `/web/uploads/images/done.svg${isImageChanged}`
export const bookingManuYear = manuYearCreator(new Date().getFullYear(), 2002)
export const bookingStripeTestKey = "pk_test_51L69awFI3LCXXijexPYZQR6VUR4rlZ9yMY7P6HaMHAUiTyYMpKbgzZQkWVDGOe0QoJ8UrjEtz3itlHOOeFQUB7UI00Mfq02v0G"
export const bookingStripeRealKey = "pk_live_51L69awFI3LCXXijeD2YqpOq1Js6fybz9M9mzqa4UXexHizUghUuR3FIAcCUXUhzaG0yPggpmKJRIDUWT7uYFgLN400DxHx06lP"
export const bookingType = [
    {
        "id": 2,
        "title": PPF_G_Short,
        "package": 3,
        "package2": 2,
        "hotPackage": 3,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-gloss.jpg${isImageChanged}`
    },
    {
        "id": 3,
        "title": PPF_A_Short,
        "package": 5,
        "package2": 5,
        "hotPackage": 5,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-matte.jpg${isImageChanged}`
    },
    {
        "id": 4,
        "title": GYEON_Coating,
        "package": 51,
        "package2": 7,
        "hotPackage": 51,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-coating.jpg${isImageChanged}`
    },
    {
        "id": 5,
        "title": GYEON_PPF,
        "package": 8,
        "package2": 0,
        "hotPackage": 0,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-ppfcoating.jpeg${isImageChanged}`
    },
    {
        "id": 6,
        "title": GYEON_D,
        "package": 9,
        "package2": 0,
        "hotPackage": 0,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-glass.jpeg${isImageChanged}`
    },
    {
        "id": 9,
        "title": PPF_T,
        "package": 25,
        "package2": 0,
        "hotPackage": 0,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-sunroof.jpeg${isImageChanged}`
    },
    {
        "id": 10,
        "title": PPF_Part,
        "package": 27,
        "package2": 26,
        "hotPackage": 0,
        "step": 6,
        "src": `/web/uploads/images/booking/detail-part.jpg${isImageChanged}`
    },
    {
        "id": 15,
        "title": COATING_service,
        "package": 49,
        "package2": 0,
        "hotPackage": 0,
        "step": 6,
        "src": `/web/uploads/images/booking/coating-services.JPG${isImageChanged}`
    },
    {
        "id": 17,
        "title": CarWin_Part,
        "package": 52,
        "package2": 52,
        "hotPackage": 53,
        "step": 6,
        "src": `/web/uploads/images/booking/coating-services.JPG${isImageChanged}`
    }
]
export const step1InputData = [
    {
        id: 1,
        label: InputText_BrandName,
        type: ID_booking_brandName,
        autoCeplete: false
    },
    {
        id: 2,
        label: InputText_CarModel,
        type: ID_booking_carModel,
        autoCeplete: false
    },
    {
        id: 3,
        label: InputText_ManuYear,
        type: ID_booking_manuYear,
        autoCeplete: false
    }
]
export const step2InputPromocode = {
    phdText: "如何獲得優惠碼？",
    popupText: "向<span className='noWrap'>Mr. Wrapper</span> 合作商戶購買易手車將獲贈優惠碼，<span className='noWrap'>Mr. Wrapper</span> 及其合作商戶亦會不定期舉向類辦活動贈送優惠碼。"
}
export const step6InputData = [
    {
        id: 1,
        label: InputText_Username,
        type: ID_booking_u_name,
        placeholder: "",
        autoComplete: false
    },
    {
        id: 2,
        label: InputText_Phone,
        type: ID_booking_u_tel,
        placeholder: "",
        autoComplete: false
    },
    {
        id: 3,
        label: InputText_Email,
        type: ID_booking_u_email,
        placeholder: "",
        autoComplete: false
    },
    {
        id: 4,
        label: InputText_LicensePlateNo,
        type: ID_booking_u_lpn,
        placeholder: Phd_booking_lpn,
        autoComplete: false
    }
]

// booking comparison data
export const tipsData = {
    id: 1,
    phdText: "關於社企學員",
    popupText: "社企學員由唐氏綜合症協會的弱勢人士組成，<span className='noWrap'>Mr. Wrapper</span>為他們提供專業鍍膜及保護膜培訓，讓他們掌握汽車護理的基礎，協助專業技術人員完成項目，能保持服務質素之餘，更可讓弱勢人士得到就業培訓機會"
}

export const detailCompare_itemType_2 = {
    id: 2,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 4,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Package_allInOne,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Package_plus,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Package_flash,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: HandWrapped,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: HandWrapped,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: HandWrapped,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_PPFThickness,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Mil8P2,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Mil7P5,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Mil6P8,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Protection,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Gloss,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_WaterEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Antifouling,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Antiyellowing,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Selfhealing,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff2,
                title: Column_HandFoam,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff2,
                title: Column_CoatingInfinite,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_Gyeon,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_GYEON_PPF,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Hyphen,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_PPFHeadlight,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Hyphen,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_MService,
                isTips: true
            },
            {
                id: 2,
                src: ``,
                title: Year2,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Year2,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Year2,
                isTips: false
            }
        ]
    ]
}
export const detailCompare_itemType_3 = {
    id: 3,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 3,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Package_mattePlus,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Package_matte,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: HandWrapped,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: HandWrapped,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_PPFThickness,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Mil8P2,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Mil7P5,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Protection,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Gloss,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_WaterEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Antifouling,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Antiyellowing,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Selfhealing,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff2,
                title: Column_HandFoam,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff2,
                title: Column_CoatingInfinite,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_Gyeon,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_GYEON_PPF,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_PPFHeadlight,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_MService,
                isTips: true
            },
            {
                id: 2,
                src: ``,
                title: Year2,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Year2,
                isTips: false
            }
        ]
    ]
}
export const detailCompare_itemType_4 = {
    id: 4,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 4,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Package_syncroPlus,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Package_syncro,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Package_pure,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Coating,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: CoatingNano,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Coating9HPlus,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Coating9H,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_CoatingThickness,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: CoatingThickness1P2,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: CoatingThicknessP5,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: CoatingThicknessP3,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_CoatingWaterEffect,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: CoatingWaterEffectTop,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: CoatingWaterEffectSkin,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Validity,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Validity48M,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Validity36M,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Validity18M,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Durability,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_CoatingGlossEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_WaterEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Dustproof,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_AntiUV,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconRate3,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff2,
                title: Column_HandFoam,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff2,
                title: Column_CoatingInfinite,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_Gyeon,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: Contain,
                isTips: false
            },
            {
                id: 4,
                src: ``,
                title: Hyphen,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: IMG_iconStaff1,
                title: Column_ScratchReparing,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconCrownOn,
                title: DeepPolish,
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconCrownOn,
                title: DeepPolish,
                isTips: false
            },
            {
                id: 4,
                src: IMG_iconCrownOff,
                title: BaseScratchRemoving,
                isTips: false
            }
        ]
    ]
}
export const detailCompare_itemType_5 = {
    id: 5,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 2,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: GYEON_PPF,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_WaterEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Antifouling,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Validity,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Validity24M,
                isTips: false
            }
        ]
    ]
}
export const detailCompare_itemType_6 = {
    id: 6,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 2,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: GYEON_D,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_WaterEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Antifouling,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Validity,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Validity12M,
                isTips: false
            }
        ]
    ]
}
export const detailCompare_itemType_9 = {
    id: 9,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 3,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Package_panoramic,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Package_half_sunroof,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_InsulationThickness,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Mil8P2,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Mil8P2,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Protection,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_InsulationEffect,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate5,
                title: "",
                isTips: false
            }
        ]
    ]
}
export const detailCompare_itemType_10 = {
    id: 10,
    title: Compare_inDetail,
    closeBtnText: Close,
    columns: 3,
    tipsItems: [
        {
            id: 1,
            src: IMG_iconStaff1,
            title: Staff_tech
        },
        {
            id: 2,
            src: IMG_iconStaff2,
            title: Staff_comm
        }
    ],
    items: [
        [
            {
                id: 1,
                src: ``,
                title: "",
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: PPF_G_Short,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: PPF_A_Short,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_PPFThickness,
                isTips: false
            },
            {
                id: 2,
                src: ``,
                title: Mil7P5,
                isTips: false
            },
            {
                id: 3,
                src: ``,
                title: Mil7P5,
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Protection,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ],
        [
            {
                id: 1,
                src: ``,
                title: Column_Selfhealing,
                isTips: false
            },
            {
                id: 2,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            },
            {
                id: 3,
                src: IMG_iconRate4,
                title: "",
                isTips: false
            }
        ]
    ]
}

// Materials PPF / Coating
export const materialsPPFBGSrc = `/web/uploads/images/materials/ppf/ppfpage-background.png${isImageChanged}`
export const materialsPPFDiamond1 = `/web/uploads/images/materials/ppf/diamond01.png${isImageChanged}`
export const materialsPPFDiamond2 = `/web/uploads/images/materials/ppf/diamond02.png${isImageChanged}`
export const materialsPPFData = [
    {
        id: 1,
        header: null,
        content: [
            {
                id: 1,
                title: "",
                titleImg: `/web/uploads/images/materials/ppf/ashland-icon.svg${isImageChanged}`,
                subTitle: "(黏貼膠 原材料)",
                content: "選用正確的黏合性原材料對於貼膜的車漆有具非常深遠的影響！<span class='noWrap'>Mr. Wrapper</span>品牌的PPF類產品的採用美國Ashland化工廠研發的Pressure Sensitive Adhesive{=PSA}原材料用作PPF保護膜底層黏貼的原材料，具有初始粘度低、環保無毒、不留膠等特點，保證擁有超強附著力同時有效延長產品使用壽命，無需擔心在日後移除貼膜時車漆受到損害。",
                contentImg: ""
            }, {
                id: 2,
                title: "",
                titleImg: "",
                subTitle: "",
                content: "",
                contentImg: `/web/uploads/images/materials/ppf/product-box01.png${isImageChanged}`
            },
            {
                id: 3,
                title: "",
                titleImg: `/web/uploads/images/materials/ppf/lubrizol-icon.svg${isImageChanged}`,
                subTitle: "(TPU 原材料)",
                content: "Lubrizol作為一家美國化學工業公司，為<span class='noWrap'>【MR WRAPPER PPF】</span>提供保護膜的主要TPU原材料，原材料卓越的韌性和延展性為<span class='noWrap'>Mr. Wrapper</span>品牌的PPF保護膜產品帶來更具抵擋外來碎石劃傷的能力，並同時具有預熱自我修復能力。",
                contentImg: ""
            }

        ],
        footer: [
            {
                id: 1,
                btnText: "立即訂購",
                btnTo: "",
                disable: true
            },
            {
                id: 2,
                btnText: "獲取商業報價",
                btnTo: "",
                disable: true
            }
        ]
    },
    {
        id: 2,
        header: [
            {
                id: 1,
                title: "<span class='noWrap'>MR WRAPPER</span>品牌",
                subTitle: "PPF 6大功能",
                icon: ""
            }
        ],
        content: [
            {
                id: 1,
                title: "",
                titleImg: ``,
                subTitle: "",
                content: "",
                contentImg: "",
                children: [
                    {
                        id: 1,
                        title: "強化保護原廠車漆",
                        titleImg: `/web/uploads/images/materials/ppf/ppt-icon01.svg${isImageChanged}`,
                        subTitle: "",
                        content: "<span class='noWrap'>MR WRAPPER PPF</span>原料來自於美國化工製造廠Lubrzol，高質素的原材料加上卓越的韌性與延展性，有效抵擋外來碎石的衝擊及劃傷。",
                        contentImg: "",
                    },
                    {
                        id: 2,
                        title: "高達100次的自我修復能力",
                        titleImg: `/web/uploads/images/materials/ppf/ppt-icon03.svg${isImageChanged}`,
                        subTitle: "",
                        content: "透過高溫自我修復技術，細微划痕可通過高溫水或日光照射的方式進行主動修復，令輕微損傷的PPF保護膜再次回復原狀！",
                        contentImg: "",
                    },
                    {
                        id: 3,
                        title: "高耐腐蝕性能",
                        titleImg: `/web/uploads/images/materials/ppf/ppt-icon05.svg${isImageChanged}`,
                        subTitle: "",
                        content: "<span class='noWrap'>MR WRAPPER PPF</span>具極佳的耐腐蝕性，有效對抗蟲屍鳥糞、鐡粉吸附、酸雨腐蝕等日常用車的常見狀況。",
                        contentImg: "",
                    }
                ]
            },
            {
                id: 2,
                title: "",
                titleImg: "",
                subTitle: "",
                content: "",
                contentImg: `/web/uploads/images/materials/ppf/product-box02.png${isImageChanged}`,
                children: null
            },
            {
                id: 3,
                title: "",
                titleImg: "",
                subTitle: "",
                content: "",
                contentImg: "",
                children: [
                    {
                        id: 1,
                        title: "高度耐黃性能",
                        titleImg: `/web/uploads/images/materials/ppf/ppt-icon02.svg${isImageChanged}`,
                        subTitle: "",
                        content: "採用耐黃技術+納米塗層技術，更有效抵禦外界污染物對薄膜表面產生的污漬和發黃現象，加強延長PPF的使用壽命，多年後仍保持亮麗如新。",
                        contentImg: "",
                    },
                    {
                        id: 2,
                        title: "提升30%車漆亮度",
                        titleImg: `/web/uploads/images/materials/ppf/ppt-icon04.svg${isImageChanged}`,
                        subTitle: "",
                        content: "光學級的TPU材質，與原車融為一體，在不影響漆面的同時提升車漆表面至少30%的亮度。",
                        contentImg: "",
                    },
                    {
                        id: 3,
                        title: "撕膜不留痕跡",
                        titleImg: `/web/uploads/images/materials/ppf/ppt-icon06.svg${isImageChanged}`,
                        subTitle: "",
                        content: "採用由美國Ashland化工廠研發的{=PSA}粘貼原材料不但環保無毒，更具不留膠等特點，在擁有超強附著力同時亦有效延長產品使用壽命，無需擔心在日後移除貼膜時車漆受到損害。",
                        contentImg: "",
                    }
                ]
            }
        ],
        footer: [
            {
                id: 1,
                btnText: "與我們聯絡",
                btnTo: "",
                disable: false
            }
        ]
    }
]
export const materialsCoatingData = [
    {
        id: 1,
        backgroundImage: `/web/uploads/images/materials/coating/_MG_4955_bg.jpg`,
        isReverse: false,
        rCover: `/web/uploads/images/materials/coating/Q2_Evo_trianglebox_syncro-3000 copy.png`,
        rTitle: 'GYEON Q² Syncro',
        rP1: "最高級別的鍍膜塗層，採用雙層塗層系統以達到最佳效果，分別為 Q² Mohs 底層和 Q² Skin 疏水層，有效期長達 36 個月。",
        rP2: "Q² Mohs 是一款易於應用的陶瓷塗層，增加車漆硬度，大大減少環境污染物的影響，並保護車漆免受永久性划痕的機會。施工後令車漆表面光滑光亮，增添光澤感，並具有極強的疏水性。",
        rP3: "Q² Skin 疏水層與使用塗層增強劑或其他鍍膜維護產品不同，具有更出色的自潔能力和對額外攻擊的優異保護。",
        enableButton: true,
        btnBookingTo: `/reservation2?item_type=4`
    },
    {
        id: 2,
        backgroundImage: `/web/uploads/images/materials/coating/_MG_4991.jpg`,
        isReverse: true,
        rCover: `/web/uploads/images/materials/coating/Q2_Evo_trianglebox_pure-3000_2.png`,
        rTitle: 'GYEON Q² Pure',
        rP1: "將車漆的外觀提升到一個全新的水平，結合了優質塗層的兩個關鍵品質 - 極高的光澤度和極佳的耐用性。Q² Pure 代表純陶瓷保護，構建了一個非常厚的層面，因此只需要這一層就可以充分發揮作用並經持久耐用，有效期長達 18 個月。",
        rP2: "",
        rP3: "",
        enableButton: true,
        btnBookingTo: `/reservation2?item_type=4`
    }
]
export const materialsCoatingSlidesPerViewData = [
    {
        id: 1,
        // imgBg: `/web/uploads/images/materials/coating/_MG_4991_2.png`,
        imgBg: `/web/uploads/images/booking/detail-glass.jpeg${isImageChanged}`,
        type: Type_Materials_Coating_None,
        coverView: {
            id: 1,
            cover: ``,
            title: "",
        },
        dataAfterClick: {
            id: 1,
            rCover: ``,
            rTitle: "",
            rP1: "",
            rP2: "",
            rP3: "",
            btnBookingTo: ""
        }
    },
    {
        id: 2,
        imgBg: `/web/uploads/images/materials/coating/_MG_4991_1.png`,
        type: Type_Materials_Coating_Items,
        coverView: {
            id: 1,
            cover: `/web/uploads/images/materials/coating/PETBox_Q2View_2021-3000.png`,
            title: "GYEON Q² View<br/>擋風玻璃鍍膜",
        },
        dataAfterClick: {
            id: 1,
            // rCover: `/web/uploads/images/materials/coating/Q2_Evo_trianglebox_pure-3000_2.png`,
            rCover: `/web/uploads/images/materials/coating/PETBox_Q2View_2021-3000.png`,
            // rTitle: "GYEON Q2 Syncro",
            rTitle: "GYEON Q² View<br/>擋風玻璃鍍膜",
            rP1: "一款持久耐用的塗層，能夠幫助玻璃排水、防塵、防污和其他環境污染物。這項獨特的配方提供極高疏水性，帶走附近的任何污垢或灰塵。GYEON Q² View 適用於任何外部玻璃，包括擋風玻璃、側窗玻璃和後視鏡，有效期長達12個月！",
            rP2: "",
            rP3: "",
            btnBookingTo: "/reservation2?item_type=6"
        }
    },
    {
        id: 3,
        imgBg: `/web/uploads/images/materials/coating/_MG_4955_bg_1.png`,
        type: Type_Materials_Coating_Items,
        coverView: {
            id: 1,
            cover: `/web/uploads/images/materials/coating/PETBox_Q2PPF_EVO_Set50ml_2021-3000.png`,
            title: "PPF專用鍍膜",
        },
        dataAfterClick: {
            id: 1,
            // rCover: `/web/uploads/images/materials/coating/Q2_Evo_trianglebox_syncro-3000 copy.png`,
            rCover: `/web/uploads/images/materials/coating/PETBox_Q2PPF_EVO_Set50ml_2021-3000.png`,
            // rTitle: "GYEON Q2 Syncro",
            rTitle: "PPF專用鍍膜",
            rP1: "獨特陶瓷塗層，適用於所有類型的 PPF 和改色膜物料。延長任何車漆保護膜的防汚能力，並最大化其疏水性能，增強光面和啞光薄膜的顏色，同時保持 PPF 原身的自我修復力。使其成為可用於 PPF 中最耐用和最疏水的塗層，有效期長達 18 個月。",
            rP2: "",
            rP3: "",
            btnBookingTo: "/reservation2?item_type=5"
        }
    },
    {
        id: 4,
        // imgBg: `/web/uploads/images/materials/coating/_MG_4955_bg_2.png`,
        imgBg: `/web/uploads/images/booking/detail-ppfcoating.jpeg${isImageChanged}`,
        type: Type_Materials_Coating_None,
        coverView: {
            id: 1,
            cover: ``,
            title: "",
        },
        dataAfterClick: {
            id: 1,
            rCover: ``,
            rTitle: "",
            rP1: "",
            rP2: "",
            rP3: "",
            btnBookingTo: ""
        }
    }
]

// ourTeam
export const ourTeamData = [
    {
        id: 1,
        teamName: OurTeam_AdminTeam,
        teamMembers: [
            {
                id: 1,
                src: `/web/uploads/images/ourTeam/Michael.jpg${isImageChanged}`,
                srcFactor: 1.2,
                name: "Michael.W",
                position: "Head of Mr Wrapper"
            }
            // ,{
            //     id: 2,
            //     src: `/web/uploads/images/ourTeam/WINI.jpg${isImageChanged}`,
            //     srcFactor: 1,
            //     name: "Wini",
            //     position: "Operations Officer"
            // }
        ]
    },
    {
        id: 2,
        teamName: OurTeam_ExecTeam,
        teamMembers: [
            {
                id: 1,
                src: `/web/uploads/images/ourTeam/HEI.jpg${isImageChanged}`,
                srcFactor: 1,
                name: "Hei",
                position: "Senior Technician"
            },
            {
                id: 2,
                src: `/web/uploads/images/ourTeam/LUN.jpg${isImageChanged}`,
                srcFactor: 1,
                name: "Lun",
                position: "Technician"
            },
            // {
            //     id: 3,
            //     src: `/web/uploads/images/ourTeam/Edward.jpg${isImageChanged}`,
            //     srcFactor: 1,
            //     name: "Edward",
            //     position: "Service Advisor"
            // }
        ]
    },
    {
        id: 3,
        teamName: OurTeam_CommunityTeam,
        teamMembers: [
            {
                id: 1,
                src: `/web/uploads/images/ourTeam/健強.jpg${isImageChanged}`,
                srcFactor: 1,
                name: "健強",
                position: "Assist"
            },
            // {
            //     id: 2,
            //     src: `/web/uploads/images/ourTeam/浩禾.jpg${isImageChanged}`,
            //     srcFactor: 1,
            //     name: "浩禾",
            //     position: "Assist"
            // },
            {
                id: 3,
                src: `/web/uploads/images/ourTeam/玉龍.png${isImageChanged}`,
                srcFactor: 1,
                name: "玉龍",
                position: "Assist"
            }
        ]
    }
]
export const ourTeamTitlebarData = [
    {
        id: 1,
        prevBtnText: "首頁",
        prevBtnTo: "/",
        prevBtnColor: "#186EB6",
        title1: "Our Amazing",
        title2: "Team",
        title3: "",
        titleColor: "black",
        lineSrc: lineYB
    }
]

// ourIdeal
export const ourIdealBG = `/web/uploads/images/ourIdeal/Group13.png${isImageChanged}`
export const ourIdealData = {
    id: 1,
    bgImage: ``,
    bgColor: "",
    isTag: false,
    tag: "",
    tagColor: "",
    title: "⾸個NGO社會服務計劃",
    slogan: "MR. WRAPPER",
    sloganColor: "#ffc100",
    lineSrc: lineYW,
    subtitle1: "",
    subtitle1Color: "",
    subtitle2: '',
    subtitle2Color: "",
    description: "Mr. Wrapper貼膜份⼦是⼀間汽⾞護理中⼼，由⿈蜂集團香港有限公司營辦，提供 PPF 貼膜、鍍膜及打蠟服務，亦是⼀間致⼒參與 NGO社會服務計劃的社會企業；透過劉卓然慈善基⾦的贊助 , 與唐⽒綜合症協會攜⼿為殘疾及復康⼈⼠提供專業汽⾞護理培訓及就業機會。",
    descriptionColor: "",
    isAlignToLeft: false,
    descBoxHeight: "100%",
    markLayer: false,
    markColor: "",
    markOpacity: 1,
    bgText: "",
    cover: ``,
    coverBgColor: "",
}
export const ourIdealFranchisingData = [
    {
        id: 3,
        bgImage: ``,
        bgColor: "",
        isTag: false,
        tag: "配套",
        tagColor: "#ffffff",
        title: "Mr. Wrapper",
        slogan: "名字的由來",
        sloganColor: "#000000",
        lineSrc: lineWB,
        subtitle1: "",
        subtitle1Color: "#ffcc01",
        subtitle2: "",
        subtitle2Color: "",
        description: `Mr. Wrapper期望讓⼤眾了解到殘疾⼈⼠跟我們⼀樣能在社會中充當重要的⾓⾊⸺ EQUITY IS THE ONLY ACCEPTABLE GOAL. 我們都是平等的。我們希望⼤眾能以「先⽣」或「⼩姐」及他們的名字去稱呼學員，讓學員得到同樣平等的對待和尊 重。同時亦寄望在客⼈在接受服務時可以透過與學員們接觸，打破從前固有的認知和誤解，親⾝感受到他們的專業⼿藝，達⾄社會共融。`,
        descriptionColor: "#000000",
        isAlignToLeft: false,
        descBoxHeight: "100%",
        markLayer: true,
        markColor: "255,204,1",
        markOpacity: 1,
        bgText: "",
        cover: ``,
        coverBgColor: "",
        swiper: true,
        swiperEffect: "",
        swiperSpeed: 0,
        swiperPosition: "",
        swiperImages: [],
        swiperBgEffect: "fade",
        swiperBgSpeed: 5000,
        swiperBgImages: [
            {
                id: 1,
                src: `/web/uploads/images/franchising/v3.jpg`
            }
        ]
    }
]
export const ourIdealL3 = [
    {
        id: 1,
        avatarSrc: `/web/uploads/images/ourIdeal/導師教導學員技巧1.png${isImageChanged}`,
        title: '幫助學員投⾝汽⾞護理⾏列',
        content: 'Mr. Wrapper貼膜份⼦秉持著基⾦會 [ 授之以⿂，不如授之以漁 ] 的宗旨，為學員們提供培訓，令他們掌握汽⾞護理、鍍膜技能，在求職上擁有更多機會。來年期望可以創造更多培訓及實習名額，助學員投⾝汽⾞護理⾏列，⾃⼒更新融入社會，讓更多使⽤服務的顧客能認識到殘疾⼈⼠和復康⼈⼠在汽⾞美容的專業技術！'
    },
    {
        id: 2,
        avatarSrc: `/web/uploads/images/ourIdeal/幫助學員投身汽車護理行列2.png${isImageChanged}`,
        title: '學員們正接受導師培訓，準備正式投入⼯作',
        content: '為確保服務質素，每位學員需進⾏長達三個⽉的培訓，提升技術⽔平才會正式投入⼯作，由洗⾞轉型為汽⾞護理，藉此讓⼤家認識學員在汽⾞護理的專業技術。每位學員在每個⼯序上都會認真⼯作，達到⼀絲不苟，他們會在Mr. Wrapper團隊內負責不同崗位，與經驗豐富的師傅配合完成整個施⼯流程。'
    }
]
export const ourIdealL5 = [
    {
        "id": 1,
        "username": "Rio梁冠聰",
        "title": "香港足球運動員",
        src: `/web/uploads/images/ourIdeal/Workshop1樓.png`,
        "content": "Mr. Wrapper九⿓灣店空間感⼗⾜，地下為 Workshop Zone，右邊區域為Wet Zone，提供服務包括：PPF 貼膜、鍍膜及打蠟等等。⽽左邊區域則為 Service Zone，為顧客提供汽⾞保養服務。最多能同時為8輛汽⾞提供服務。",
        "star": 0,
        "position": "top",
        "ig": "chung_05"
    },
    {
        "id": 2,
        "username": "Sam Man文卓森",
        "title": "旁白君",
        "src": `/web/uploads/images/ourIdeal/休息室及Office環境2樓.png`,
        "content": "⼀樓為休息室及辦公室，為顧客提供休憩地⽅。休息室備有影⾳設備、雜誌及咖啡等等，靜候座駕完成服務取⾞，讓顧客感受到無微不⾄的服務。",
        "star": 5,
        "position": "top",
        "ig": "samm0123"
    },
    {
        "id": 3,
        "username": "Sam Man文卓森",
        "title": "旁白君",
        "src": `/web/uploads/images/ourIdeal/休息室及Office環境2樓2.png`,
        "content": "Mr. Wrapper在店⾯設計⽅⾯也下了⼼思，採⽤⼤⾃然元素配搭⾊彩繽紛的裝，營造明亮舒適的休閒空間。除了期望為員⼯帶來良好的⼯作環境，亦希望顧客在繁忙鬧市當中享受⽚刻的寧靜。",
        "star": 5,
        "position": "top",
        "ig": "samm0123"
    }
]

// partnership
export const partnershipSrc = `/web/uploads/images/new_reg_img/partnership_bg.jpg${isImageChanged}`

// policy of use
export const ppfPreface = [
    {
        id: 1,
        content: '零售客戶預約安裝汽車PPF車漆保護膜之條款'
    },
    {
        id: 2,
        content: "請仔細閱讀本文，因本文所述均為有關閣下權利及義務的重要資料，及適用於閣下的限制及有限責任條款。"
    },
    {
        id: 3,
        content: "本合約包括閣下向我們購買並貼上汽車保護膜（下稱「PPF」）及╱或其周邊產品或相關服務時實行的條款。閣下確認已閱讀並完全知悉及同意該等條款的內容，並在落實訂單購買 PPF時，表示同意及接受此等條款的約束。"
    },
    {
        id: 4,
        content: "<span class='noWrap'>Mr. Wrapper</span>可能不時修訂此等條款。因閣下受到該條款的約束，所以閣下應不時查閱此等條款。"
    }
]
export const ppfPolicyOfUse = [
    {
        id: 1,
        content: "訂購╱合約的構成：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span>只會接納香港境內的訂購要求。",
                subItems: null
            },
            {
                id: 2,
                content: "PPF 訂單可於 <span class='noWrap'>Mr. Wrapper</span> 網上平台進行報價及／或預約。閣下須提供車款（包括品牌、型號、年份）以及車輛是否曾經改裝的準確資料，並選擇適用的 PPF 套餐。每款 PPF 套餐包含的服務內容將以網上平台顯示的內容為準，<span class='noWrap'>Mr. Wrapper</span> 明確保留不時更改 PPF 套餐服務內容的權利，並將不作另行通知。",
                subItems: null
            },
            {
                id: 3,
                content: "閣下亦可於門市惠顧。",
                subItems: null
            },
            {
                id: 4,
                content: " 指定 PPF 套餐內的特定工序由技師負責，部份工序則由社會企業 (下稱「社企」)學員負責。",
                subItems: null
            },
            {
                id: 5,
                content: "所有PPF訂單均須在 <span class='noWrap'>Mr. Wrapper</span>接納後，方為作實。該等訂單接納與否均由 <span class='noWrap'>Mr. Wrapper</span>自行決定。",
                subItems: null
            },
            {
                id: 6,
                content: "如閣下發現任何因網絡、設備及╱或系統故障引致之訂單錯誤，閣下須於二十四（24）小時內通知 <span class='noWrap'>Mr. Wrapper</span> 跟進相關訂單，否則訂購一經 <span class='noWrap'>Mr. Wrapper</span> 確定接納，將不能取消。",
                subItems: null
            },
            {
                id: 7,
                content: " 當 <span class='noWrap'>Mr. Wrapper</span> 將其接納有關訂購的通知發送給閣下時，本條款則會構成合約。訂購一經 <span class='noWrap'>Mr. Wrapper</span> 確定接納，將不能取消。",
                subItems: null
            },
            {
                id: 8,
                content: "<span class='noWrap'>Mr. Wrapper</span>在確認收到訂單貨款後，會發出電郵及短訊通知閣下訂單作實。",
                subItems: null
            },
        ]
    },
    {
        id: 2,
        content: "價格及付款：",
        subItems: [
            {
                id: 1,
                content: "PPF之服務費應為訂購當日於本網站刊出的價格表所列明的價格。除另有明確註明外，所有售價均以港元為報價貨幣。",
                subItems: null
            },
            {
                id: 2,
                content: "於本網站完成訂購後，閣下須以以下方式完成付款：",
                subItems: [
                    {
                        id: 1,
                        content: "信用卡網上付款，必須於網上平台直接完成付款程序。"
                    }
                ]
            },
            {
                id: 3,
                content: "所有銀行所收取之支付及╱或其他費用（不論原因為何）均由閣下獨自承擔。",
                subItems: null
            },
        ]
    },
    {
        id: 3,
        content: "PPF服務：",
        subItems: [
            {
                id: 1,
                content: "客戶不得擅自更改預定安裝 PPF之車輛。",
                subItems: null
            },
            {
                id: 2,
                content: "訂單確認並完成付款後，<span class='noWrap'>Mr. Wrapper</span>會以電郵及短訊通知閣下確認PPF安裝日期及時間。",
                subItems: null
            },
            {
                id: 3,
                content: "閣下如欲在首次與<span class='noWrap'>Mr. Wrapper</span> 商確認 PPF安裝日期及時間後更改安裝服務，必須自行聯絡 <span class='noWrap'>Mr. Wrapper</span>，惟 <span class='noWrap'>Mr. Wrapper</span> 保留絕對權決定是否接納有關要求。",
                subItems: null
            },
            {
                id: 4,
                content: "閣下需把要貼上PPF 之汽車於約定之服務時間內駕駛待安裝之汽車至九龍灣臨利街1號富洋工業中心地下5號舖，並於 PPF 安裝妥當後於同一地點取回。安裝 PPF 需時大約需要六(6)個工作天，惟實際時間需按 <span class='noWrap'>Mr. Wrapper</span> 工作進度而定。",
                subItems: null
            },
            {
                id: 5,
                content: "如閣下未能於指定時間取回汽車，閣下需要支付每天港幣 $500 作為 行政 、 附加 費以及汽車停泊費用 。不足一天亦當一天計算。",
                subItems: null
            },
            {
                id: 6,
                content: "懸掛8 號颱風或黑色暴雨警告生效期間，所有貼上PPF 之服務將會暫停。<span class='noWrap'>Mr. Wrapper</span> 將於 8 號颱風或黑色暴雨警告停止懸掛後二十四（24）小時內繼續進行有關安裝及貼上之服務。",
                subItems: null
            },
            {
                id: 7,
                content: "<span class='noWrap'>Mr. Wrapper</span> 將在合理範圍內盡力按照預約時間為閣下提供服務，但如因惡劣天氣或其他無法控制的原因下導致貼上 PPF 之服務有任何延誤或未能完成時，<span class='noWrap'>Mr. Wrapper</span>概不承擔任何責任。",
                subItems: null
            },
            {
                id: 8,
                content: "於安裝PPF 時，如因如客戶車輛因故障或其他環境因素限制或非 <span class='noWrap'>Mr. Wrapper</span>可合理控制的情形下，而導致安裝服務未能提供時，客戶需自行尋求維修方案並與 <span class='noWrap'>Mr. Wrapper</span> 商議更改安裝日期及時間。<span class='noWrap'>Mr. Wrapper</span> 有權酌情收取相關行政及附加費用。",
                subItems: null
            },
        ]
    },
    {
        id: 4,
        content: "收貨及風險轉移：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span>對任何 PPF服務的責任，包括但不限於損毀，所承擔的責任僅限於實際直接損失、且不超過最高賠償限額$10,000。<span class='noWrap'>Mr. Wrapper</span> 不承擔任何其它損失或損害 (包括但不限於利潤、收入、利息及未來業務的損失)，無論這些其它損失和損害是特殊性或是間接性，無論 <span class='noWrap'>Mr. Wrapper</span> 是否在受理服務之前或之後知曉有這些損失或損害的風險。",
                subItems: null
            },
            {
                id: 2,
                content: "客戶在每次服務中只能提出一次索賠，且與該服務相關的所有損失或損壞的賠償是完全並最終的。",
                subItems: [
                    {
                        id: 1,
                        content: "信用卡網上付款，必須於網上平台直接完成付款程序。"
                    }
                ]
            },
            {
                id: 3,
                content: "PPF一經貼上均不設任何退款及退訂，亦不可替換其他型號產品。",
                subItems: null
            },
            ,
            {
                id: 4,
                content: "如閣下對於 PPF 的服務有任何意見，閣下需於驗收時即時提出，否則閣下會被視為已接受該 PPF 產品為正常及符合合約所訂。如閣下於驗收時提出已被貼上 PPF之問題，<span class='noWrap'>Mr. Wrapper</span> 會即時跟進。如經 <span class='noWrap'>Mr. Wrapper</span>核實確認 PPF 之服務未達標準，<span class='noWrap'>Mr. Wrapper</span> 會即時替閣下更換。",
                subItems: null
            },
            ,
            {
                id: 5,
                content: "PPF的所有權、損失風險及損壞會在貼上閣下的汽車時由閣下承擔。",
                subItems: null
            },
        ]
    },
    {
        id: 5,
        content: "有限保用：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span>對任何 PPF服務的責任，包括但不限於損毀，所承擔的責任僅限於實際直接損失、且不超過最高賠償限額$10,000。<span class='noWrap'>Mr. Wrapper</span> 不承擔任何其它損失或損害 (包括但不限於利潤、收入、利息及未來業務的損失)，無論這些其它損失和損害是特殊性或是間接性，無論 <span class='noWrap'>Mr. Wrapper</span> 是否在受理服務之前或之後知曉有這些損失或損害的風險。",
                subItems: null
            }
        ]
    },
    {
        id: 6,
        content: "責任限制：",
        subItems: [
            {
                id: 1,
                content: "因閣下不當打理、使用或改裝而造成任何財產損失、開支、法律責任，<span class='noWrap'>Mr. Wrapper</span>概不負責。",
                subItems: null
            },
            {
                id: 2,
                content: "不論在任何情况下，任何一方無須就因履行或未有履行合約或因根據此等條款提供、履行或使用任何銷售的貨品或提供的服務而造成任何間接的、特殊的或後果性的賠償，向另一方面負責（無論該等賠償是否因違約、違反保證 、疏忽所致）。除任何一方因其本身疏忽所致的傷亡而須負上的責任（該等責任不會獲任何條款所限制），任何一方均無須負責任何間接或懲罰性賠償或第三者向另一方提出的申索（若合約中另有指明則除外）。",
                subItems: null
            },
        ]
    },
    {
        id: 7,
        content: "更改：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span> 保留隨時更改使用條款及細則的權利，而不會作另行通知。所有訂單均按照此等條款而獲接納，而此等條款會代替於 <span class='noWrap'>Mr. Wrapper</span> 網站或其他地方刊登的其他條款，及推翻任何由經銷商提出或於交易過程中提供的其他條款。",
                subItems: null
            },
            {
                id: 2,
                content: "如有任何爭議，<span class='noWrap'>Mr. Wrapper</span>保留最終決定權。",
                subItems: null
            },
        ]
    },
    {
        id: 8,
        content: "不可抗力：",
        subItems: [
            {
                id: 1,
                content: "任何因天災、火災、水災、意外、暴亂、戰爭、政府干預、禁運、罷工、勞工問題、儀器故障、或其他超出 <span class='noWrap'>Mr. Wrapper</span>控制範圍的原因導致或造成延誤或不能履行義務，<span class='noWrap'>Mr. Wrapper</span>均不會負責。",
                subItems: null
            },
            {
                id: 2,
                content: "產品及零件供應量視乎存貨量而定，如貨量不足時，<span class='noWrap'>Mr. Wrapper</span>有權取消訂單及／或自行分配銷售額。",
                subItems: null
            },
        ]
    },
    {
        id: 9,
        content: "客戶私隱保障：",
        subItems: [
            {
                id: 1,
                content: "客戶提供的個人資料，將作<span class='noWrap'>Mr. Wrapper</span> 網上平台客戶計劃運作及管理之用，包括管理預約及發放優惠碼之用；或作為日後資料搜集、發展計劃，以及為客戶提供通訊和資料之用。若客戶未能提供或更新有關資料，或會導致 <span class='noWrap'>Mr. Wrapper</span> 網上平台未能為客戶提供既定的客戶服務及有關優惠，及因此服務可能會被終止。",
                subItems: null
            },
            {
                id: 2,
                content: "<span class='noWrap'>Mr. Wrapper</span>不會公開、編輯或透露客戶的個人資料及保存在 <span class='noWrap'>Mr. Wrapper</span>網上平台客戶服務中的非公開內容。有關個人資料只用於為客戶送上最新消息、信息及提供服務。",
                subItems: null
            },
            {
                id: 3,
                content: "如客戶想拒絕繼續接受<span class='noWrap'>Mr. Wrapper</span>的市場及推廣資料， 請電郵致cs@mrwrapper.com.hk。",
                subItems: null
            },
        ]
    },
    {
        id: 10,
        content: "終止服務：",
        subItems: [
            {
                id: 1,
                content: "客戶如有違反本客戶條款的行為，<span class='noWrap'>Mr. Wrapper</span>有絕對的酌情權以任何理由拒絕及／或撤銷任何申請者的開戶申請，及終止及／或取消其服務。若服務因任何原因被<span class='noWrap'>Mr. Wrapper</span>終止，所有相關優惠碼亦會被立即註銷。",
                subItems: null
            },
            {
                id: 2,
                content: "客戶如希望主動終止服務，須於網上平台下載有關表格，填寫後透過網上平台上載或郵寄繳交予 <span class='noWrap'>Mr. Wrapper</span>。",
                subItems: null
            }
        ]
    },
]
export const coatingPreface = [
    {
        id: 1,
        content: '零售客戶預約鍍膜服務之條款'
    },
    {
        id: 2,
        content: "請仔細閱讀本文，因本文所述均為有關閣下權利及義務的重要資料，及適用於閣下的限制及有限責任條款。"
    },
    {
        id: 3,
        content: "本合約包括閣下向我們購買並鍍膜服務及其施工的條款。閣下確認已閱讀並完全知悉及同意該等條款的內容，並在落實訂單預約 <span class='noWrap'>Mr. Wrapper</span> 之鍍膜服務時，表示同意及接受此等條款的約束。"
    },
    {
        id: 4,
        content: "<span class='noWrap'>Mr. Wrapper</span>可能不時修訂此等條款。因閣下受到該條款的約束，所以閣下應不時查閱此等條款。"
    }
]
export const coatingPolicyOfUse = [
    {
        id: 1,
        content: "訂購╱合約的構成：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span> 只會接納香港境內的訂購要求。",
                subItems: null
            },
            {
                id: 2,
                content: "鍍膜服務之訂單可於 <span class='noWrap'>Mr. Wrapper</span> 網上平台進行報價及／或預約。閣下須提供車款（包括品牌、型號、年份）以及車輛是否曾經改裝的準確資料，並選擇適用的 鍍膜服務套餐。每款鍍膜服務套餐包含的服務內容將以網上平台顯示的內容為準， <span class='noWrap'>Mr. Wrapper</span> 明確保留不時更改鍍膜服務套餐服務內容的權利，並將不作另行通知。",
                subItems: null
            },
            {
                id: 3,
                content: "閣下亦可於門市惠顧。",
                subItems: null
            },
            {
                id: 4,
                content: "指定鍍膜服務套餐內的特定工序由技師負責，部份工序則由社會企業 (下稱「社企」)學員負責。",
                subItems: null
            },
            {
                id: 5,
                content: "所有鍍膜服務訂單均須在 <span class='noWrap'>Mr. Wrapper</span> 接納後，方為作實。該等訂單接納與否均由 <span class='noWrap'>Mr. Wrapper</span> 自行決定。",
                subItems: null
            },
            {
                id: 6,
                content: "如閣下發現任何因網絡、設備及╱或系統故障引致之訂單錯誤，閣下須於二十四（24）小時內通知 <span class='noWrap'>Mr. Wrapper</span> 跟進相關訂單，否則訂購一經 <span class='noWrap'>Mr. Wrapper</span> 確定接納，將不能取消。",
                subItems: null
            },
            {
                id: 7,
                content: "當 <span class='noWrap'>Mr. Wrapper</span> 將其接納有關訂購的通知發送給閣下時，本條款則會構成合約。訂購一經 <span class='noWrap'>Mr. Wrapper</span> 確定接納，將不能取消。",
                subItems: null
            },
            {
                id: 8,
                content: "<span class='noWrap'>Mr. Wrapper</span> 在確認收到訂單貨款後，會發出電郵及短訊通知閣下訂單作實。",
                subItems: null
            },
        ]
    },
    {
        id: 2,
        content: "價格及付款：",
        subItems: [
            {
                id: 1,
                content: "鍍膜服務應為訂購當日於本網站刊出的價格表所列明的價格。除另有明確註明外，所有售價均以港元為報價貨幣。",
                subItems: null
            },
            {
                id: 2,
                content: "於本網站完成訂購後，閣下須以以下方式完成付款：",
                subItems: [
                    {
                        id: 1,
                        content: "信用卡網上付款，必須於網上平台直接完成付款程序。"
                    }
                ]
            },
            {
                id: 3,
                content: "所有銀行所收取之支付及╱或其他費用（不論原因為何）均由閣下獨自承擔。",
                subItems: null
            },
        ]
    },
    {
        id: 3,
        content: "鍍膜服務：",
        subItems: [
            {
                id: 1,
                content: "客戶不得擅自更改預定施工鍍膜服務之車輛型號。",
                subItems: null
            },
            {
                id: 2,
                content: "訂單確認並完成付款後，<span class='noWrap'>Mr. Wrapper</span> 會以電郵及短訊通知閣下確認鍍膜服務施工日期及時間。",
                subItems: null
            },
            {
                id: 3,
                content: "閣下如欲在首次與 <span class='noWrap'>Mr. Wrapper</span> 確認鍍膜服務施工日期及時間後更改施工服務，必須自行聯絡 <span class='noWrap'>Mr. Wrapper</span>，惟 <span class='noWrap'>Mr. Wrapper</span> 保留絕對權決定是否接納有關要求。",
                subItems: null
            },
            {
                id: 4,
                content: "閣下需把要施工鍍膜服務之汽車於約定之服務時間內駕駛待安裝之汽車至九龍灣臨利街1號富洋工業中心地下5號舖，並於鍍膜服務施工妥當後於同一地點取回。施工鍍膜服務需時大約三(3)個工作天，惟實際時間需按 <span class='noWrap'>Mr. Wrapper</span> 工作進度而定。",
                subItems: null
            },
            {
                id: 5,
                content: "如閣下未能於指定時間取回汽車，閣下需要支付每天港幣 $500 作為行政 、 附加費以及汽車停泊費用 。不足一天亦當一天計算。",
                subItems: null
            },
            {
                id: 6,
                content: "如閣下欲更改施工鍍膜服務，閣下必須在已約定之服務時間最少一(1)個工作天前以電郵要求服務更改，如更改鍍膜服務套餐亦須補回差價，惟 <span class='noWrap'>Mr. Wrapper</span> 保留絕對權決定是否接納有關要求。",
                subItems: null
            },
            {
                id: 7,
                content: "懸掛 8 號颱風或黑色暴雨警告生效期間，所有鍍膜服務之施工將會暫停。<span class='noWrap'>Mr. Wrapper</span> 將於 8 號颱風或黑色暴雨警告停止懸掛後二十四（24）小時內繼續進行有關施工服務。",
                subItems: null
            },
            {
                id: 8,
                content: "<span class='noWrap'>Mr. Wrapper</span> 將在合理範圍內盡力按照預約時間為閣下提供服務，但如因惡劣天氣或其他無法控制的原因下導致施工鍍膜服務有任何延誤或未能完成時， <span class='noWrap'>Mr. Wrapper</span> 概不承擔任何責任。",
                subItems: null
            },
            {
                id: 9,
                content: "於施工鍍膜服務時，如因如客戶車輛因故障或其他環境因素限制或非 <span class='noWrap'>Mr. Wrapper</span> 可合理控制的情形下，而導致施工服務未能提供時，客戶需自行尋求維修方案並與 <span class='noWrap'>Mr. Wrapper</span> 商議更改安裝日期及時間。<span class='noWrap'>Mr. Wrapper</span> 有權酌情收取相關行政及附加費用。",
                subItems: null
            }
        ]
    },
    {
        id: 4,
        content: "收貨及風險轉移：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span> 對任何鍍膜服務的責任，包括但不限於損毀，所承擔的責任僅限於實際直接損失、且不超過最高賠償限額$10，000。<span class='noWrap'>Mr. Wrapper</span> 不承擔任何其它損失或損害 (包括但不限於利潤、收入、利息及未來業務的損失)，無論這些其它損失和損害是特殊性或是間接性，無論 <span class='noWrap'>Mr. Wrapper</span> 是否在受理服務之前或之後知曉有這些損失或損害的風險。",
                subItems: null
            },
            {
                id: 2,
                content: "客戶在每次服務中只能提出一次索賠，且與該服務相關的所有損失或損壞的賠償是完全並最終的。",
                subItems: null
            },
            {
                id: 3,
                content: "鍍膜服務一經施工後均不設任何退款及退訂，亦不可替換其他型號產品。",
                subItems: null
            },
            ,
            {
                id: 4,
                content: "如閣下對於施工鍍膜服務有任何意見，閣下需於驗收時即時提出，否則閣下會被視為已接受該鍍膜服務為正常及符合合約所訂。如閣下於驗收時提出已被施工鍍膜服務之問題，<span class='noWrap'>Mr. Wrapper</span> 會即時跟進。如經<span class='noWrap'>Mr. Wrapper</span> 核實確認鍍膜服務之施工未達標準，<span class='noWrap'>Mr. Wrapper</span> 會即時替閣下更換。",
                subItems: null
            }
        ]
    },
    {
        id: 5,
        content: "有限保用：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span> 確保物料及工藝質素沒有缺陷，及符合鍍膜服務之規格要求。鍍膜的保用期將根據閣下選購的套餐而定。",
                subItems: null
            }
        ]
    },
    {
        id: 6,
        content: "責任限制：",
        subItems: [
            {
                id: 1,
                content: "因閣下不當打理、使用或改裝而造成任何財產損失、開支、法律責任，  <span class='noWrap'>Mr. Wrapper</span> 概不負責。",
                subItems: null
            },
            {
                id: 2,
                content: "不論在任何情况下，任何一方無須就因履行或未有履行合約或因根據此等條款提供、履行或使用任何銷售的貨品或提供的服務而造成任何間接的、特殊的或後果性的賠償，向另一方面負責（無論該等賠償是否因違約、違反保證 、疏忽所致）。除任何一方因其本身疏忽所致的傷亡而須負上的責任（該等責任不會獲任何條款所限制），任何一方均無須負責任何間接或懲罰性賠償或第三者向另一方提出的申索（若合約中另有指明則除外）。",
                subItems: null
            },
        ]
    },
    {
        id: 7,
        content: "更改：",
        subItems: [
            {
                id: 1,
                content: "<span class='noWrap'>Mr. Wrapper</span> 保留隨時更改使用條款及細則的權利，而不會作另行通知。所有訂單均按照此等條款而獲接納，而此等條款會代替於 <span class='noWrap'>Mr. Wrapper</span> 網站或其他地方刊登的其他條款，及推翻任何由經銷商提出或於交易過程中提供的其他條款。",
                subItems: null
            },
            {
                id: 2,
                content: "如有任何爭議，<span class='noWrap'>Mr. Wrapper</span> 保留最終決定權。",
                subItems: null
            },
        ]
    },
    {
        id: 8,
        content: "不可抗力：",
        subItems: [
            {
                id: 1,
                content: "任何因天災、火災、水災、意外、暴亂、戰爭、政府干預、禁運、罷工、勞工問題、儀器故障、或其他超出  Mr.  Wrapper  控制範圍的原因導致或造成延誤或不能履行義務，<span class='noWrap'>Mr. Wrapper</span> 均不會負責。",
                subItems: null
            },
            {
                id: 2,
                content: "產品及零件供應量視乎存貨量而定，如貨量不足時，<span class='noWrap'>Mr. Wrapper</span> 有權取消訂單及／或自行分配銷售額。",
                subItems: null
            },
        ]
    },
    {
        id: 9,
        content: "客戶私隱保障：",
        subItems: [
            {
                id: 1,
                content: "客戶提供的個人資料，將作 <span class='noWrap'>Mr. Wrapper</span> 網上平台客戶計劃運作及管理之用，包括管理預約及發放優惠碼之用；或作為日後資料搜集、發展計劃，以及為客戶提供通訊和資料之用。若客戶未能提供或更新有關資料，或會導致 <span class='noWrap'>Mr. Wrapper</span> 網上平台未能為客戶提供既定的客戶服務及有關優惠，及因此服務可能會被終止。",
                subItems: null
            },
            {
                id: 2,
                content: "<span class='noWrap'>Mr. Wrapper</span> 不會公開、編輯或透露客戶的個人資料及保存在 <span class='noWrap'>Mr. Wrapper</span> 網上平台客戶服務中的非公開內容。有關個人資料只用於為客戶送上最新消息、信息及提供服務。",
                subItems: null
            },
            {
                id: 3,
                content: "如客戶想拒絕繼續接受 <span class='noWrap'>Mr. Wrapper</span> 的市場及推廣資料， 請電郵致cs@mrwrapper.com.hk。",
                subItems: null
            },
        ]
    },
    {
        id: 10,
        content: "終止服務：",
        subItems: [
            {
                id: 1,
                content: "客戶如有違反本客戶條款的行為，<span class='noWrap'>Mr. Wrapper</span> 有絕對的酌情權以任何理由拒絕及／或撤銷任何申請者的開戶申請，及終止及／或取消其服務。若服務因任何原因被<span class='noWrap'>Mr. Wrapper</span> 終止，所有相關優惠碼亦會被立即註銷。",
                subItems: null
            },
            {
                id: 2,
                content: "客戶如希望主動終止服務，須於網上平台下載有關表格，填寫後透過網上平台上載或郵寄繳交予 <span class='noWrap'>Mr. Wrapper</span>。",
                subItems: null
            }
        ]
    },
]

// homepage popup ad
export const popupImages = [
    {
        url: `/web/uploads/images/popupAD/popup_ad.png${isImageChanged}`
    }
]
export const iconAfterPickup = `/web/uploads/images/popupAD/clock.svg${isImageChanged}`
export const iconAfterPickupForMobile = `/web/uploads/images/popupAD/mobile/clock_white.svg${isImageChanged}`

// quizzes result
export const quizzes_qmark = `/web/uploads/images/quizzes/qustion_mark.png${isImageChanged}`
export const quizzes_bg = `/web/uploads/images/quizzes/quizzes_bg.png${isImageChanged}`
export const quizzes_bg_mobile = `/web/uploads/images/quizzes/mobile/quizzes_bg_mobile.png${isImageChanged}`
export const resultOfQuiz = [
    {
        id: 1,
        type: "A1",
        resultArray: [
            [1, 1, 1, 2],
            [1, 1, 1, 3],
            [1, 1, 3, 2],
            [1, 1, 3, 3]
        ],
        rcmdService_phd: RcmdService_phd,
        rcmdService: PPF_GA,
        rcmdService_explanation: "相信您也明白路面碎石的破壞力，即使擁有室內車位也不能避免路上的意外，對於保護性的執著，我們建議您為愛車進行PPF的護理，光面PPF擁有更好的光澤度，啞面PPF讓車輛更具氣質；或者您也有考慮過為愛車鍍膜，但鍍膜的車輛未能抵抗碎石，而且您沒有定期為愛車進行專業清潔，鍍膜塗層可能在非專業的洗車中磨損，所以<span class='noWrap'>Mr. Wrapper</span>建議您使用PPF",
        rcmdService_btnGroup: [
            {
                id: 1,
                text: PPF_G_Short,
                assignTo: "/reservation2?item_type=2"
            },
            {
                id: 2,
                text: PPF_A_Short,
                assignTo: "/reservation2?item_type=3"
            }
        ],
        rcmdSubService_phd: RcmdSubService_phd,
        rcmdSubService_explanation: "另一個選擇是為愛車進行局部貼膜，讓經常出現磨損的部份加強保護性，同時為愛車進行鍍膜，但需要小心清潔防止磨損鍍膜表層",
        rcmdSubService_btnGroup: [],
        extraService: []
    },
    {
        id: 2,
        type: "A2",
        resultArray: [
            [1, 1, 1, 1],
            [1, 1, 2, 1],
            [1, 1, 3, 1],
            [1, 2, 1, 1],
            [1, 2, 2, 1],
            [2, 1, 2, 1]
        ],
        rcmdService_phd: RcmdService_phd,
        rcmdService: PPF_GA,
        rcmdService_explanation: "相信您也明白路面碎石的破壞力，即使擁有室內車位也不能避免路上的意外，平衡過光澤度和保護性後，我們建議您為愛車進行PPF的護理，光面PPF擁有更好的光澤度，啞面PPF讓車輛更具氣質；或者您也有考慮過為愛車鍍膜，但鍍膜的車輛未能抵抗碎石，雖然您會有定期為愛車進行專業清潔，但路面上的風險磨損會破壞您所花的心機護理時間，所以<span class='noWrap'>Mr. Wrapper</span>建議您使用PPF",
        rcmdService_btnGroup: [
            {
                id: 1,
                text: PPF_G_Short,
                assignTo: "/reservation2?item_type=2"
            },
            {
                id: 2,
                text: PPF_A_Short,
                assignTo: "/reservation2?item_type=3"
            }
        ],
        rcmdSubService_phd: RcmdSubService_phd,
        rcmdSubService_explanation: "另一個選擇是為愛車進行局部貼膜，讓經常出現磨損的部份加強保護性，同時為愛車進行鍍膜，但需要小心清潔防止磨損鍍膜表層",
        rcmdSubService_btnGroup: [],
        extraService: []
    },
    {
        id: 3,
        type: "A3",
        resultArray: [
            [1, 1, 2, 2],
            [1, 1, 2, 3],
            [1, 2, 1, 2],
            [1, 2, 1, 3],
            [1, 2, 2, 2],
            [1, 2, 2, 3],
            [1, 2, 3, 2],
            [1, 2, 3, 3],
            [2, 1, 1, 2],
            [2, 1, 1, 3],
            [2, 1, 2, 2],
            [2, 1, 2, 3],
            [2, 1, 3, 2],
            [2, 1, 3, 3],
            [2, 2, 1, 2],
            [2, 2, 1, 3],
            [2, 2, 3, 2],
            [2, 2, 3, 3]
        ],
        rcmdService_phd: RcmdService_phd,
        rcmdService: Coating,
        rcmdService_explanation: "追求光澤度的您，我們建議您使用鍍膜，鍍膜後的車身比較容易清潔，但要小心的是不專業的洗車有機會把積存的沙石破壞了表面塗層形成花痕，我們建議您加強清潔的次數，同時採用專業的泡沫洗車，<span class='noWrap'>Mr. Wrapper</span>的客戶可以購買客戶尊享洗車套票，由香港唐氏綜合症協會的殘疾人士經培訓後主理，讓您的愛車光潔如新時亦可以為社會出一分力。",
        rcmdService_btnGroup: [
            {
                id: 1,
                text: GYEON_Coating,
                assignTo: "/reservation2?item_type=4"
            }
        ],
        rcmdSubService_phd: RcmdSubService_phd,
        rcmdSubService_explanation: "另一個選擇是為愛車進行PPF貼膜，PPF貼膜有更好的防護性，在清洗方面也可以防止不專業的洗車形成磨損，光面PPF可以突顯車漆的亮度，也跟你的洗車習慣更配合。",
        rcmdSubService_btnGroup: [],
        extraService: []
    },
    {
        id: 4,
        type: "A4",
        resultArray: [
            [2, 2, 2, 2],
            [2, 2, 2, 3]
        ],
        rcmdService_phd: RcmdService_phd,
        rcmdService: PPF_GA,
        rcmdService_explanation: "因為你沒有固定的室內停車位，PPF貼膜有更好的防護性，可以防止鳥糞、碎石、雨水等侵蝕，幸好您沒有在行駛中被碎石撞花的經驗，但追求保護性的您應該是謹慎的人，同時您為愛車清潔的習慣比較少，使用PPF後我們建議您每月進行一至兩次的清潔，光面PPF可以突顯車漆的亮度，讓您的愛車更光潔如新。",
        rcmdService_btnGroup: [
            {
                id: 1,
                text: PPF_G_Short,
                assignTo: "/reservation2?item_type=2"
            },
            {
                id: 2,
                text: PPF_A_Short,
                assignTo: "/reservation2?item_type=3"
            }
        ],
        rcmdSubService_phd: RcmdSubService_phd,
        rcmdSubService_explanation: "另一個選擇是為愛車進行局部貼膜，讓經常出現磨損的部份加強保護性，同時為愛車進行鍍膜，但需要小心清潔防止磨損鍍膜表層",
        rcmdSubService_btnGroup: [],
        extraService: []
    },
    {
        id: 5,
        type: "A5",
        resultArray: [
            [1, 2, 3, 1],
            [2, 1, 1, 1],
            [2, 1, 3, 1],
            [2, 2, 1, 1],
            [2, 2, 2, 1],
            [2, 2, 3, 1]
        ],
        rcmdService_phd: RcmdService_phd,
        rcmdService: Coating,
        rcmdService_explanation: "追求光澤度的您，我們建議您使用鍍膜，<span class='noWrap'>Mr. Wrapper</span>使用的是Gyeon品牌物料，鍍膜後的車身比較容易清潔，但要小心的是不專業的洗車有機會把積存的沙石破壞了表面塗層形成花痕，我們建議您採用專業的泡沫洗車，<span class='noWrap'>Mr. Wrapper</span>的客戶可以購買客戶尊享洗車套票，由香港唐氏綜合症協會的殘疾人士經培訓後主理，讓您的愛車光潔如新時亦可以為社會出一分力。",
        rcmdService_btnGroup: [
            {
                id: 1,
                text: GYEON_Coating,
                assignTo: "/reservation2?item_type=4"
            }
        ],
        rcmdSubService_phd: RcmdSubService_phd,
        rcmdSubService_explanation: "另一個選擇是為愛車進行PPF貼膜，因為你沒有固定的室內停車位或在路上曾被碎石撞花撞凹，PPF貼膜有更好的防護性，可以防止鳥糞、碎石、雨水等侵蝕，光面PPF可以突顯車漆的亮度，讓您的愛車更光潔如新。",
        rcmdSubService_btnGroup: [],
        extraService: []
    }
]
const segment = {
    basic: 'basic',
    medium: 'medium',
    advanced: 'advanced',
};
export const questioinQuiz = {
    quizTitle: '',
    quizSynopsis: '',
    nrOfQuestions: '4',
    questions: [
        {
            question: '您經常停泊的位置是？',
            // questionPic: 'https://dummyimage.com/600x400/000/fff&text=X',
            questionType: 'text',
            answerSelectionType: 'single',
            answers: [
                '有蓋室內',
                '露天室外'
            ],
            correctAnswer: '2',
            messageForCorrectAnswer: 'Correct answer. Good job.',
            messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
            explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            point: '20',
            segment: segment.advanced,
        },
        {
            question: '您有於高速公路或碎石路面被碎石撞花撞凹的經驗嗎？',
            questionType: 'text',
            answerSelectionType: 'single',
            answers: [
                '有',
                '沒有',
            ],
            correctAnswer: '1',
            messageForCorrectAnswer: 'Correct answer. Good job.',
            messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
            explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            point: '20',
            segment: segment.basic,
        },
        {
            question: '您追求的是保護性還是光澤性？',
            questionType: 'text',
            answerSelectionType: 'single',
            answers: [
                '保護性',
                '光澤性',
                '我全都要！'
            ],
            correctAnswer: '2',
            messageForCorrectAnswer: 'Correct answer. Good job.',
            messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
            explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            point: '10',
        },
        {
            question: '您會多久為愛車進行專業清洗？',
            questionType: 'text',
            answerSelectionType: 'single',
            answers: [
                '一星期一次或以上',
                '一個月一次或以上',
                '多於一個月一次',
            ],
            correctAnswer: '2',
            messageForCorrectAnswer: 'Correct answer. Good job.',
            messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
            explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            point: '30',
            segment: segment.medium,
        }
    ],
    "appLocale": {
        "landingHeaderText": "",
        "startQuizBtn": "",
    }
};

// franchising
export const franchisingData = [
    {
        id: 1,
        bgImage: `/web/uploads/images/franchising/v1.png${isImageChanged}`,
        bgColor: "",
        isTag: false,
        tag: "",
        tagColor: "",
        title: "Mr. Wrapper",
        slogan: "社企的⾃營魅⼒",
        sloganColor: "",
        lineSrc: lineYW,
        subtitle1: "全港⾸個社企特許經營項⽬！",
        subtitle1Color: "",
        subtitle2: '自身成功經營模型，\n\r利潤保證！',
        subtitle2Color: "",
        description: "",
        descriptionColor: "",
        isAlignToLeft: false,
        descBoxHeight: "100%",
        markLayer: false,
        markColor: "",
        markOpacity: 1,
        bgText: "",
        cover: ``,
        coverBgColor: "",
        swiper: true,
        swiperEffect: "fade",
        swiperSpeed: 1000,
        swiperPosition: "center",
        swiperImages: [
            {
                id: 1,
                src: `/web/uploads/images/franchising/swiper/bob_head-01.png${isImageChanged}`
            },
            {
                id: 2,
                src: `/web/uploads/images/franchising/swiper/bob_head-02.png${isImageChanged}`
            },
            {
                id: 3,
                src: `/web/uploads/images/franchising/swiper/bob_head-03.png${isImageChanged}`
            }
        ]
    },
    {
        id: 2,
        bgImage: `/web/uploads/images/franchising/v2.jpg${isImageChanged}`,
        bgColor: "",
        isTag: true,
        tag: "配套",
        tagColor: "#ffffff",
        title: "",
        slogan: "任何⼈！",
        sloganColor: "#ffcc01",
        lineSrc: lineYW,
        subtitle1: "",
        subtitle1Color: "#ffffff",
        subtitle2: "替你開店、引流、推廣",
        subtitle2Color: "#ffffff",
        description: `Mr. Wrapper開始接受特許經營加盟，任何⼈、任何社福機構都能成為Mr. Wrapper的特許經營負責⼈；透過我們豐富經驗的市場策劃團隊，替你從開設⾨店、⾃助引流、配合網上推廣及網⾴下單系統，您只需要的只有⼈⼒資源及⽇常營運！`,
        descriptionColor: "#ffffff",
        isAlignToLeft: true,
        descBoxHeight: "100%",
        markLayer: true,
        markColor: "0, 0, 0",
        markOpacity: 0.7,
        bgText: "",
        cover: ``,
        coverBgColor: "",
        swiper: false,
        swiperEffect: "",
        swiperSpeed: 0,
        swiperPosition: "",
        swiperImages: []
    },
    {
        id: 3,
        bgImage: `/web/uploads/images/franchising/v3.jpg${isImageChanged}`,
        bgColor: "",
        isTag: true,
        tag: "配套",
        tagColor: "#ffffff",
        title: "",
        slogan: "任何地方！",
        sloganColor: "#ffffff",
        lineSrc: lineYB,
        subtitle1: "更多弱勢社群就業",
        subtitle1Color: "#ffcc01",
        subtitle2: "",
        subtitle2Color: "",
        description: `Mr. Wrapper 的總店位置於九⿓灣，我們希望透過特許經營計劃，在任何地⽅都能夠看到Mr. Wrapper的特許經營店，透過向每⼀位⾞主提供汽⾞護理的會籍計劃，為更多弱勢社群提供就業服務 ！`,
        descriptionColor: "#ffffff",
        isAlignToLeft: false,
        descBoxHeight: "100%",
        markLayer: true,
        markColor: "14,99,170",
        markOpacity: 0.97,
        bgText: "",
        cover: ``,
        coverBgColor: "",
        swiper: false,
        swiperEffect: "",
        swiperSpeed: 0,
        swiperPosition: "",
        swiperImages: []
    },
    {
        id: 4,
        bgImage: ``,
        bgColor: "#f3f3f3",
        isTag: true,
        tag: "配套",
        tagColor: "#000",
        title: "",
        slogan: "休憩空間",
        sloganColor: "#000000",
        lineSrc: lineYB,
        subtitle1: "⭐24小時輕鬆網上預約",
        subtitle1Color: "#000",
        subtitle2: "⭐24小時輕鬆網上預約\t\n⭐24小時輕鬆網上預約",
        subtitle2Color: "#000",
        description: ``,
        descriptionColor: "#000000",
        isAlignToLeft: true,
        descBoxHeight: "84%",
        markLayer: true,
        markColor: "#bfa",
        markOpacity: 1,
        bgText: "",
        cover: `/web/uploads/images/franchising/v6.jpg${isImageChanged}`,
        coverBgColor: "94,162,223",
        swiper: false,
        swiperEffect: "",
        swiperSpeed: 0,
        swiperPosition: "",
        swiperImages: []
    },
    {
        id: 5,
        bgImage: `/web/uploads/images/franchising/v7.jpg${isImageChanged}`,
        bgColor: "",
        isTag: true,
        tag: "系統",
        tagColor: "#186eb6",
        title: "",
        slogan: "任何時間！",
        sloganColor: "#000000",
        lineSrc: lineYB,
        subtitle1: "24⼩時系統化管理～",
        subtitle1Color: "#186eb6",
        subtitle2: "",
        subtitle2Color: "#186eb6",
        description: `透過Mr Wrapper 的網⾴報價平台，顧客可以24⼩時輕鬆地在網上進⾏報價及預約；無論在凌晨或者假期，透過網上報價的平台優勢，無時無刻都可以收到訂單！`,
        descriptionColor: "#000000",
        isAlignToLeft: true,
        descBoxHeight: "84%",
        markLayer: false,
        markColor: "",
        markOpacity: 0,
        bgText: "任何時間",
        cover: ``,
        coverBgColor: "",
        swiper: false,
        swiperEffect: "",
        swiperSpeed: 0,
        swiperPosition: "",
        swiperImages: []
    },
    {
        id: 6,
        bgImage: ``,
        bgColor: "#eeeeee",
        isTag: true,
        tag: "系統",
        tagColor: "#186eb6",
        title: "",
        slogan: "中央訂單顯示屏",
        sloganColor: "#000000",
        lineSrc: lineYB,
        subtitle1: "",
        subtitle1Color: "#186eb6",
        subtitle2: "更有效地\n\r管理你的施工團隊！",
        subtitle2Color: "#186eb6",
        description: `透過我們系統的中央分流及管理，你可以從中央顯示屏清晰地掌握⽬前的施⼯狀態跟接下來的訂單詳情，更有效地管理你的施⼯團隊！`,
        descriptionColor: "#000000",
        isAlignToLeft: false,
        descBoxHeight: "70%",
        markLayer: false,
        markColor: "",
        markOpacity: 0,
        bgText: "",
        cover: ``,
        coverBgColor: "94,162,223",
        swiper: true,
        swiperEffect: "fade",
        swiperSpeed: 5000,
        swiperPosition: "top",
        swiperImages: [
            {
                id: 1,
                src: `/web/uploads/images/franchising/swiper/order_display.png${isImageChanged}`
            }
        ]
    }
]
export const servicingAndChargingData = {
    id: 1,
    title: "服務及收費",
    lineSrc: lineYB,
    desc: "特許經營店跟九⿓灣總店不同的是，特許經營店主要提供汽⾞護理的會籍計劃，主要針對已進⾏PPF 或鍍膜施⼯的⾞輛進⾏進⼀步的護理及修復服務。",
    deItemTitle: "洗車服務",
    deItems: [
        {
            id: 1,
            price: 840,
            expireDay: 30,
            count: 4,
            icon: `/web/uploads/images/franchising/servicingAndCharging/wash-car.svg${isImageChanged}`,
            services: [
                {
                    id: 1,
                    content: "ALL IN ONE - 全車護理服務",
                    disable: false,
                    subitems: [
                        {
                            id: 1,
                            content: "量子抗菌塗層",
                        },
                        {
                            id: 2,
                            content: "全車泡沫洗車",
                        }
                    ]
                },
                {
                    id: 2,
                    content: "RAZE 光觸媒車箱消毒",
                    disable: true,
                    subitems: []
                },
                {
                    id: 3,
                    content: "精緻手工蠟（1次）",
                    disable: true,
                    subitems: []
                }
            ],
            memTitle: "會籍計劃",
            memServices: [
                {
                    id: 1,
                    content: "無限時享用休憩區",
                    disable: false,
                    subitems: [
                        {
                            id: 1,
                            content: "免費飲品吧服務",
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            price: 1200,
            expireDay: 30,
            count: 6,
            icon: `/web/uploads/images/franchising/servicingAndCharging/wash-car.svg${isImageChanged}`,
            services: [
                {
                    id: 1,
                    content: "ALL IN ONE - 全車護理服務",
                    disable: false,
                    subitems: [
                        {
                            id: 1,
                            content: "量子抗菌塗層",
                        },
                        {
                            id: 2,
                            content: "全車泡沫洗車",
                        }
                    ]
                },
                {
                    id: 2,
                    content: "RAZE 光觸媒車箱消毒",
                    disable: false,
                    subitems: []
                },
                {
                    id: 3,
                    content: "精緻手工蠟（1次）",
                    disable: true,
                    subitems: []
                }
            ],
            memTitle: "會籍計劃",
            memServices: [
                {
                    id: 1,
                    content: "無限時享用休憩區",
                    disable: false,
                    subitems: [
                        {
                            id: 1,
                            content: "免費飲品吧服務",
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            price: 1560,
            expireDay: 30,
            count: 8,
            icon: `/web/uploads/images/franchising/servicingAndCharging/wash-car.svg${isImageChanged}`,
            services: [
                {
                    id: 1,
                    content: "ALL IN ONE - 全車護理服務",
                    disable: false,
                    subitems: [
                        {
                            id: 1,
                            content: "量子抗菌塗層",
                        },
                        {
                            id: 2,
                            content: "全車泡沫洗車",
                        }
                    ]
                },
                {
                    id: 2,
                    content: "RAZE 光觸媒車箱消毒",
                    disable: false,
                    subitems: []
                },
                {
                    id: 3,
                    content: "精緻手工蠟（1次）",
                    disable: false,
                    subitems: []
                }
            ],
            memTitle: "會籍計劃",
            memServices: [
                {
                    id: 1,
                    content: "無限時享用休憩區",
                    disable: false,
                    subitems: [
                        {
                            id: 1,
                            content: "免費飲品吧服務",
                        }
                    ]
                }
            ]
        }
    ],
    orItemTitle: "貼膜、鍍膜及消毒",
    orItems: [
        {
            id: 1,
            icon: `/web/uploads/images/franchising/servicingAndCharging/ppf.svg${isImageChanged}`,
            serName: "PPF保護膜",
            require: "5-7日",
            changedDynamically: true,
            price: 20900
        },
        {
            id: 2,
            icon: `/web/uploads/images/franchising/servicingAndCharging/gyeon.svg${isImageChanged}`,
            serName: "鍍膜",
            require: "3-5日",
            changedDynamically: true,
            price: 5500
        },
        {
            id: 3,
            icon: `/web/uploads/images/franchising/servicingAndCharging/disinfect.svg${isImageChanged}`,
            serName: "消毒",
            require: "1日",
            changedDynamically: false,
            price: 600
        }
    ],
    tips: "*以2名師傅計算，視乎人手及環境配置可提升效率"
}
export const ContactWithUsData = {
    id: 1,
    bgImage: `/web/uploads/images/homepage/instantQuote/instant-quote-bg.svg${isImageChanged}`,
    blockRImage: blockBY_R,
    blockLImage: blockBY_L,
    title1: "與我們的",
    title2: "特許經營代表會面",
    representatives: [
        {
            id: 1,
            src: `/web/uploads/images/ourTeam/Michael.jpg${isImageChanged}`,
            name: "Michael.W",
            position: "Head of Mr Wrapper"
        },
        {
            id: 2,
            src: `/web/uploads/images/ourTeam/Michael.jpg${isImageChanged}`,
            name: "Michael.W",
            position: "Head of Mr Wrapper"
        }
    ],
    tableInputs: {
        id: 1,
        title_phd: "請填寫以下資料， 我們會儘快聯繫你：",
        text_btn: "送出",
        inputs: [
            {
                id: 1,
                type: "text",
                myKey: "name",
                label: "聯絡人"
            },
            {
                id: 2,
                type: "radio",
                myKey: "title",
                label: "",
                option1: "先生",
                option2: "小姐",
            },
            {
                id: 3,
                type: "tel",
                myKey: "phone",
                label: "聯絡電話",
            },
            {
                id: 4,
                type: "email",
                myKey: "email",
                label: "電郵",
            },
            {
                id: 5,
                type: "text",
                myKey: "aName",
                label: "機構名稱（如適用）",
            },
            {
                id: 6,
                type: "radio",
                myKey: "isA",
                label: "是否Section 88的機構？",
                option1: "是",
                option2: "否",
            },
            {
                id: 7,
                type: "text",
                myKey: "planType",
                label: "計劃受聘的弱勢人士類別",
            },
            {
                id: 8,
                type: "radio",
                myKey: "isExp",
                label: "過往是否有營商經驗",
                option1: "有",
                option2: "沒有",
            }
        ]
    }
}

// works
export const worksButtonGroup = [
    {
        id: 1,
        type: ID_works_all,
        text: All,
    },
    {
        id: 2,
        type: PPF,
        text: PPF,
    },
    {
        id: 3,
        type: COATING,
        text: Coating,
    }
]

// footer
export const logoEsg = `/web/uploads/images/homepage/footer/esg-logo.svg${isImageChanged}` //11111111111111111111111111111111111111111
export const logoNgo = `/web/uploads/images/homepage/footer/ngo-logo.svg${isImageChanged}`
export const logoWasphk = `/web/uploads/images/homepage/footer/wasphk-logo.svg${isImageChanged}`
export const logoCaringcompany = `/web/uploads/images/homepage/footer/caringcompany-logo.svg${isImageChanged}` //11111111111111111111111111111111111111111
export const logoSrSupporter = `/web/uploads/images/homepage/footer/sr-supporter-logo.svg${isImageChanged}` //11111111111111111111111111111111111111111
export const logoHappyCompany = `/web/uploads/images/homepage/footer/LogoHappyCompany.svg${isImageChanged}`
export const logoHeartToHeart = `/web/uploads/images/homepage/footer/LogoHeartToHeart.svg${isImageChanged}`
export const IconContactMap = `/web/uploads/images/homepage/footer/contact-map.svg${isImageChanged}`
export const IconContactTelaphone = `/web/uploads/images/homepage/footer/contact-telaphone.svg${isImageChanged}`
export const IconContactWhatsapp = `/web/uploads/images/homepage/footer/contact-whatsapp.svg${isImageChanged}`
export const IconContactMail = `/web/uploads/images/homepage/footer/contact-mail.svg${isImageChanged}`
export const IconFollowusFb = `/web/uploads/images/homepage/footer/followus-fb.svg${isImageChanged}`
export const IconFollowusIg = `/web/uploads/images/homepage/footer/followus-ig.svg${isImageChanged}`
export const slogan = `/web/uploads/images/homepage/footer/slogan.svg${isImageChanged}`
export const footerLine = `/web/uploads/images/homepage/footer/footer-line.svg${isImageChanged}`
export const footerData = [
    {
        id: 1,
        src: logoWasphk
    },
    {
        id: 2,
        src: logoNgo
    },
    {
        id: 3,
        src: logoHappyCompany
    },
    {
        id: 4,
        src: logoHeartToHeart
    },
    {
        id: 5,
        src: logoCaringcompany
    },
    {
        id: 6,
        src: logoSrSupporter
    },
    {
        id: 7,
        src: logoEsg
    }
]
export const innerFooterData = [
    {
        id: 1,
        src: logoNgo
    },
    {
        id: 2,
        src: logoWasphk
    },
    {
        id: 3,
        src: logoCaringcompany
    },
    {
        id: 4,
        src: logoSrSupporter
    },
    {
        id: 5,
        src: logoHappyCompany
    },
    {
        id: 6,
        src: logoEsg
    }
]

// common
export const bookingFooterNgo = `/web/uploads/images/booking/footer/ngo.svg${isImageChanged}`
export const bookingFooterWasphk = `/web/uploads/images/booking/footer/wasphk-logo.svg${isImageChanged}`
export const bookingFooterCaringcompany = `/web/uploads/images/booking/footer/caringcompany-logo.svg${isImageChanged}`
export const bookingFooterSrSupporter = `/web/uploads/images/booking/footer/sr-supporter-logo.svg${isImageChanged}`
export const bookingFooterLogoHappyCompany = `/web/uploads/images/booking/footer/LogoHappyCompany.svg${isImageChanged}`
export const errorMessege = {
    [ID_booking_brandName]: ErrorMsg_BrandName,
    [ID_booking_carModel]: ErrorMsg_CarModel,
    [ID_booking_manuYear]: ErrorMsg_ManuYear,
    [ID_booking_u_name]: ErrorMsg_u_name,
    [ID_booking_u_tel]: ErrorMsg_u_tel,
    [ID_booking_u_email]: ErrorMsg_u_email,
    [ID_booking_u_lpn]: ErrorMsg_u_lpn,
}




